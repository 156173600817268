import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeExperience,updateExperience } from "../../../../../../Redux/experience";
import Spinner from "../../../../../../Shared/spinner";

const Accordion = ({ index, item }) => {
  const dispatch = useDispatch();


  const [experienceForm,setExperienceForm] = useState( item ?  item : {
    jobTitle: "",
    employer: "",
    startDate: {
      fieldValue: "",
      displayValue: "",
    },
    endDate: {
      fieldValue: "",
      displayValue: "",
    },
    currentStudy: false,
    location: "",
    summary: "",
  })

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setLoading] = useState(false);

  console.log(item)
  console.log(experienceForm)


  {
    /***Handle Closing and Opening of Accordion Bar */
  }
  const handleAccordion = () => {
    setIsActive((prev) => !prev);
  };
  {
    /***Remove Education */
  }
  const removeExperienceEntry = () => {
    dispatch(removeExperience(index))
  }
  const handleInputs = (e) => {
    const { value, name } = e.target;
    setExperienceForm({
      ...experienceForm,
      [name]: value,
    });
  };
  {
    /****Handle Date Fields */
  }
  const handleDate = (e) => {
    const { value, name } = e.target;

    const splitDate = value.split("-");
    const year = splitDate[0];
    const monthNumber = splitDate[1];

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentMonth = monthNames[parseInt(monthNumber, 10) - 1];
    const JoinDate = currentMonth + " " + year;

    setExperienceForm({
      ...experienceForm,
      [name]: {
        fieldValue: value,
        displayValue: JoinDate,
      },
    });
  };

  {
    /****handle Checkbox */
  }
  const handleCheckBox = () => {
    setExperienceForm((prevForm) => ({
      ...prevForm,
      currentStudy: !prevForm.currentStudy,
    }));
  };

  const handleSave = () => {
    setLoading(true); // Set loading to true immediately

    const timeout = setTimeout(() => {
      const payload = {
        position: index,
        data: experienceForm,
      };

      dispatch(updateExperience(payload));
      setIsActive((prev) => !prev);
      setLoading(false); // Set loading back to false after the timeout
    }, 2000); // 2 seconds

    return () => clearTimeout(timeout);
  };

  const disabled = !experienceForm.jobTitle || !experienceForm.employer;

  return (
    <>
      <div className="w-full h-auto flex flex-row gap-2  font-poppins group relative ">
        <div
          className={`w-[97%] min-h-[60px] max-h-auto flex flex-col gap-2 border-[1px] shadow-sm rounded-sm `}
        >
          <div
            onClick={handleAccordion}
            className="w-full  h-[60px] flex flex-row items-center justify-between px-4 cursor-pointer "
          >
            {/**HEading Data**/}
            <div className="flex flex-col  ">
              <h3 className="text-[14px] font-sans font-[600] group-hover:text-[#1A91F0] transition-colors  ">
                {experienceForm.employer && experienceForm.jobTitle
                  ? experienceForm.jobTitle + " at " + experienceForm.employer
                  : "(Not Specified)"}
                {/* Bs Software Engineering at University of Swat */}
              </h3>
              <span className="text-[12px] font-sans text-gray-500 ">
                {experienceForm.startDate && !experienceForm.currentStudy
                  ? experienceForm.startDate.displayValue +
                    " - " +
                    experienceForm.endDate.displayValue
                  : experienceForm.startDate &&
                    experienceForm.startDate.displayValue + " - " + "Present"}
                {/* Jan 2024 - Jan 2028 */}
              </span>
            </div>
            <span
              className={`material-symbols-outlined cursor-pointer group-hover:text-[#1A91F0] transition-colors ${
                isActive ? " rotate-180 " : ""
              } transition-all  `}
            >
              expand_more
            </span>
          </div>
          {/* sm:h-[620px] md:h-[540px] */}
          <div
            className={`w-full ${
              isActive ? "  " : "h-0 opacity-0 pointer-events-none "
            }   transition-all    `}
          >
            <div className="flex h-auto flex-col sm:gap-4 lg:gap-6  pt-2 pb-3 px-4 ">
              {/**School And Degree**/}
              <div className="flex sm:flex-col md:flex-row justify-between gap-4 ">
                {/**Job Title**/}
                <div className="sm:w-full md:w-1/2 flex flex-col gap-1 wrapper   ">
                  <label className=" text-[#828BA2] text-[14px] font-inter   ">
                    Job Title
                  </label>
                  <input
                    value={experienceForm.jobTitle}
                    onChange={handleInputs}
                    name="jobTitle"
                    type="text"
                    required
                    className="input px-4 py-4 h-[40px]  "
                  />
                </div>
                {/**Employer**/}
                <div className="sm:w-full md:w-1/2 flex flex-col gap-1 wrapper   ">
                  <label className=" text-[#828BA2] text-[14px] font-inter   ">
                    Employer
                  </label>
                  <input
                    value={experienceForm.employer}
                    onChange={handleInputs}
                    name="employer"
                    type="text"
                    required
                    className="input px-4 py-4 h-[40px]  "
                  />
                </div>
              </div>
              {/**Start & End Date and Degree**/}
              <div className="flex sm:flex-col md:flex-row justify-between gap-4 ">
                {/**Start and End Date**/}
                <div className="sm:w-full md:w-1/2 flex flex-col gap-1 wrapper   ">
                  <div className="flex flex-col gap-6">
                    <div className="flex flex-row gap-2">
                      {/**Start Date**/}
                      <div className=" sm:w-1/2 md:w-[48%] flex flex-col gap-1 wrapper   ">
                        <label className=" text-[#828BA2] text-[14px] font-iter   ">
                          Start Date
                        </label>
                        <input
                          value={experienceForm.startDate.fieldValue}
                          onChange={handleDate}
                          name="startDate"
                          type="month"
                          required
                          className="input px-4 py-4 h-[40px]  "
                        />
                      </div>
                      {/**End Date**/}
                      <div className=" sm:w-1/2 md:w-[48%] flex flex-col gap-1 wrapper   ">
                        <label className=" text-[#828BA2] text-[14px] font-iter   ">
                          End Date
                        </label>

                        <input
                          disabled={experienceForm.currentStudy == true}
                          value={
                            !experienceForm.currentStudy &&
                            experienceForm.endDate.fieldValue
                          }
                          onChange={handleDate}
                          name="endDate"
                          type="month"
                          required
                          className="input px-4 py-4 h-[40px] disabled:cursor-not-allowed  "
                        />
                      </div>
                    </div>
                    {/***Currently Study***/}
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        onChange={handleCheckBox}
                        type="checkbox"
                        checked={experienceForm.currentStudy}
                        // value={experienceForm.currentStudy}
                        required
                        className="input px-4 py-4   "
                      />
                      <label className=" text-[#828BA2] text-[14px] font-inter   ">
                        Currently Work here
                      </label>
                    </div>
                  </div>
                </div>
                {/**City **/}
                <div className="w-full flex flex-col gap-1 wrapper   ">
                  <label className=" text-[#828BA2] text-[14px] font-inter   ">
                    Location
                  </label>
                  <input
                    onChange={handleInputs}
                    value={experienceForm.city}
                    name="city"
                    type="text"
                    required
                    className="input px-4 py-4 h-[40px]  "
                  />
                </div>
              </div>
              {/**Description**/}
              <div className="flex flex-col gap-2 mt-1 ">
                <label className=" text-black text-[16px] font-poppins    ">
                  Professional Summary
                </label>
                <textarea
                  value={experienceForm.summary}
                  onChange={handleInputs}
                  type="text"
                  required
                  name="summary"
                  placeholder="Describe your employment details"
                  className="input px-4 py-4 h-[200px] overflow-y-auto resize-none  "
                />
              </div>
              <div className=" flex justify-end item-center   ">
                <button
                  disabled={disabled}
                  onClick={handleSave}
                  className="bg-[#0a072bfd] hover:bg-[#0a072bdb] disabled:cursor-not-allowed disabled:bg-[#0a072bdb] text-white font-roboto px-8 py-2 rounded-sm  "
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <span
          onClick={removeExperienceEntry}
          className="material-symbols-outlined text-gray-200 hidden group-hover:block hover:text-[#1A91F0] cursor-pointer mt-[20px] absolute right-[-20px]  "
        >
          delete
        </span>
      </div>
    </>
  );
};

export default Accordion;
