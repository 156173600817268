import React from 'react'
import { useDispatch } from 'react-redux'
import { removeHobby } from '../../../../../../Redux/hobbies'

const Hobby = ({item,index}) => {

  const dispatch = useDispatch()

  const DeleteHobby = () => {
    dispatch(removeHobby(index))
  }

  return (
    <div className=' px-6 py-1 flex flex-row gap-2 items-center bg-[#e9f0f8] rounded-md group transition-all '  >
      <h2 className=' text-[16px]   ' >
       {item}
        </h2>
      <span
         onClick={DeleteHobby}
          className="material-symbols-outlined text-gray-400 hidden group-hover:block hover:text-[#1A91F0] cursor-pointer transition-all  "
        >
          delete
        </span>
    </div>
  )
}

export default Hobby