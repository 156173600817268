import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hobbies: JSON.parse(localStorage.getItem("Hobbies")) || [],
};

export const hobbiesSlice = createSlice({
  name: "hobbies",
  initialState,
  reducers: {
    addHobby: (state, action) => {
      console.log(action.payload)
      state.hobbies = [...state.hobbies, action.payload];
      localStorage.setItem("Hobbies",JSON.stringify(state.hobbies))
    },

    removeHobby: (state, action) => {
      const position = action.payload
      const filteredHobbies = state.hobbies.filter((item, index) => {
        return position !== index;
      });

      localStorage.setItem("Hobbies",JSON.stringify(filteredHobbies))
      state.hobbies = filteredHobbies;
    },

  
  },
});

// Action creators are generated for each case reducer function
export const { addHobby, removeHobby } = hobbiesSlice.actions;

export default hobbiesSlice.reducer;
