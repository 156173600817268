import { createSlice } from "@reduxjs/toolkit";
import { current, produce } from "immer";

const initialState = {
  educationList: JSON.parse(localStorage.getItem("EducationList")) || [],
};


export const educationSlice = createSlice({
  name: "education",
  initialState,
  reducers: {
    addNewEducation: (state, action) => {
      state.educationList = [...state.educationList, ""];
    },  
    removeEducation: (state, action) => {
      const position = action.payload;

      const filteredEducationList = state.educationList.filter((item,index)=>{
        return position !== index
      })

     state.educationList = filteredEducationList
     localStorage.setItem("EducationList",JSON.stringify(filteredEducationList))

    },
    updateEducation: (state, action) => {
      const { position, data } = action.payload;

      const newList =  produce(state, (draft) => {
        if (draft.educationList && draft.educationList.length > 0) {
          draft.educationList[position] = data;
          
        }
      });

      localStorage.setItem("EducationList",JSON.stringify(newList.educationList))

      return newList
      
    },
    
  },
});

// Action creators are generated for each case reducer function
export const { addNewEducation, removeEducation, updateEducation } =
  educationSlice.actions;

export default educationSlice.reducer;
