import React, { useState } from 'react'
import styles from '../../style'
import SlideTab from './Tab'
import Header from '../../Shared/Header'
import Footer from '../../Shared/Footer'
import { AllTemplates } from '../Templates/templates'
import ResumeCard from './resume'
import { Helmet } from 'react-helmet'

const Template = () => {

    const [filterItem, setFilterItem] = useState(AllTemplates)

    const handleFilterItems = (cat) => {


        const FilteredItem = AllTemplates?.filter((template)=>{
            return template.category == cat
        })

        console.log(FilteredItem,"FILTERED")
        setFilterItem(FilteredItem)

    }

    const handleAll = () => {
        setFilterItem(AllTemplates)
    }
    
   return (
        <div >
            <Helmet>
            <meta charSet="utf-8" />
            <title>Resume Templates</title>
            <link rel="canonical" href="/resume-templates" />
         </Helmet>
            <Header/>
            <div className={`w-full ${styles.paddingX} flex justify-center items-center flex-col mx-auto max-w-[1280px]`}>
                <div className="text-center w-full flex flex-col justify-center items-center pt-14 ">
                    <h1 className=' flex gap-1 font-poppins font-semibold text-[32px] lg:text-[44px] text-slate-700 '>
                        Resume 
                        <span className='text-[#2A84FF]'>
                        Templates
                        </span>
                    </h1>
                    <p className='w-full md:w-[780px] text-[18px] font-poppins py-4'>Every resume template is expertly designed and follows the precise "resume rules" that employers seek. With field-tested resume templates, you'll stand out and get hired more quickly.</p>
                </div>
            
    

                <div className='mt-10 overflow-hidden'>
                    <SlideTab handleAll={handleAll}  handlefilterItem={handleFilterItems} setFilterItem={setFilterItem} />
                </div>

                <hr className='py-2'/>
                <div className='sm:w-full md:w-[90%] xl:w-[80%]  max-w-[7xl] mx-auto h-auto flex flex-row justify-center gap-5 mt-[20px] mb-[20px] flex-wrap ' >
                    {
                        filterItem?.map((item,index)=>{
                            return(
                               <ResumeCard item={item} />
                            )
                        })
                    }
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default Template
