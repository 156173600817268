import React from 'react'
import MySkill from './mySkills';
import {useDispatch,useSelector } from 'react-redux';
import { addSkill } from '../../../../../../Redux/skills';
import { setTabCompletion,selectedTab } from "../../../../../../Redux/builder";
import { useNavigate } from "react-router-dom";


const AddSkills = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const skill = useSelector((state)=>state.allSkills.skills)
  const currentTemplate = useSelector((state)=> state.template.currentTemplate)


  const AddNew = () => {
     dispatch(addSkill())
  }

  const handleNext = () => {
    dispatch(setTabCompletion({ tabName: "Skills", completed: true }));
    navigate(`/resume-builder/${currentTemplate.name}/hobbies`)
    dispatch(selectedTab("Hobbies"))
  }

  const handleBack = () =>{
    dispatch(selectedTab('Experience'));
    navigate(`/resume-builder/${currentTemplate.name}/experience`)
  }


  return (
    <div className='w-full h-auto flex flex-col gap-4' >
      {
        skill &&  skill.length > 0 ?
         skill.map((item,index)=>{
          return (
              <MySkill key={index} index={index} item={item}  />
          )
         })
         :""

      }
       <div
        onClick={AddNew}
        className="w-[97%] flex flex-row gap-2 items-center hover:bg-[#1a90f01c] cursor-pointer py-2 px-2 transition-all rounded-sm "
      >
        <span className="material-symbols-outlined text-[#1A91F0] text-[20px] font-[600] ">
          add
        </span>
        <span className="text-[#1A91F0] text-[14px] font-poppins font-[600] tracking-[0.3px] ">
          {skill && skill.length > 0
            ? "Add one more skill"
            : "Add Skill"}
        </span>
      </div>
      <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-4 mt-[30px]  ">
        <button onClick={handleBack} className="sm:w-full lg:w-1/2  h-[40px] text-black font-alata  flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm hover:bg-[#0097e6] hover:text-white     ">
          {" "}
          Back{" "}
        </button>
        <button
          onClick={handleNext}
          className={`sm:w-full lg:w-1/2  h-[40px] text-white font-alata cursor-pointer   flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm bg-[#0097e6]  hover:bg-[#0a072bfd]    `}
        >
          {" "}
          Next{" "}
        </button>
      </div>
    </div>
  )
}

export default AddSkills