import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentTemplate } from "../../Redux/template"
import "./resume.css"

const ResumeCard = ({ item }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {name} = item

  const handle = () => {
    window.location.replace(`/resume-builder/${name}/profile`)
    // navigate(`/resume-builder/${name}/profile`)
    dispatch(setCurrentTemplate(item))
  }
// d5e3f1
  return (
    <div className='w-[300px] h-[450px] bg-[#e5e9ed] overflow-hidden flex flex-col gap-4 justify-center px-2 py-2 Item_img'>

      <div className=' mt-1 item'>
        <img src={item.image}  className='w-[285px] h-[380px] cursor-pointer  ' />
        <div className='Item_img_hover'>
          <button onClick={handle}>Select Template</button>
        </div>
      </div>
      <div className='flex justify-between'>

      <p className='text-black text-[18px] flex justify-start font-poppins ml-1 capitalize'>{item.title}</p>
      <p className='text-black text-[18px] flex justify-start font-poppins ml-1 capitalize'>{item.category}</p>
      </div>
    </div>

  )
}

export default ResumeCard