import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import '../index.css'



const Preview = ({ setisResetActive, isPreviewActive, setIsPreviewActive }) => {

  const navigate = useNavigate()

  const currenttemplate = useSelector(
    (state) => state.template.currentTemplate
  );

  const Component = lazy(() =>
    import(`../../../Templates${currenttemplate.path}`)
  );

  const personalData = useSelector((state) => state.profile.personalData);
  const educationList = useSelector((state) => state.education.educationList);
  const skills = useSelector((state) => state.allSkills.skills);
  const experience = useSelector((state) => state.experience.experienceList);
  const hobbies = useSelector((state) => state.myhobbies.hobbies);
  const languages = useSelector((state) => state.mylanguages.languages)

  const handleResetpopup = () => {
    setisResetActive(true)
  }

  const isDataPresent = () => {
    for (const key in personalData) {
      if (personalData.hasOwnProperty(key)) {
        if (personalData[key] && personalData[key].trim() !== "") {
          return true;
        }
      }
    }
    return false;
  };

  const isActive = isPreviewActive

  const show = isActive ? "sm:translate-x-0 " : "sm:translate-x-full lg:translate-x-0 "

  const handleClose = () => {
    setIsPreviewActive(false)
  }

  return (
    <>
      <div className={`sm:w-full ${show} lg:w-[60%] relative  previewPage  px-2 py-1  bg-blue-50 sm:bg-opacity-40 lg:bg-opacity-100  sm:backdrop-blur-lg lg:backdrop-blur-0 overflow-y-auto overflow-x-hidden sm:fixed  top-0 sm:z-[1500]  lg:sticky lg:z-0 `}>
        <div className=' relative -top-11 md:top-0 -left-16 lg:left-0  '>
          <div className="scale-75 md:scale-95 min-h-[700px] bg-white rounded-md w-[620px] 2xl:w-[550px]  lg:min-h-[700px] overflow-hidden  2xl:scale-110  max-h-auto md:mx-auto mt-[4%] md:mt-[6%] lg:mt-[12%]  mb-[5%]  ">

            {
              isDataPresent() ?
                <Suspense>
                  <Component
                    personalData={personalData}
                    educationList={educationList}
                    skills={skills}
                    experience={experience}
                    hobbies={hobbies}
                    languages={languages}
                  />
                </Suspense>
                :
                <div className="w-full h-[700px] " >
                  <svg width="560" height="700" viewBox="0 0 650 870" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="650" height="870" rx="20" fill="white" />
                    <rect x="36" y="80" width="578.969" height="20.9593" rx="10.4796" fill="#F3F3F3" />
                    <rect x="36" y="119" width="578.969" height="20.9593" rx="10.4796" fill="#F3F3F3" />
                    <rect x="36" y="156" width="578.969" height="20.9593" rx="10.4796" fill="#F3F3F3" />
                    <rect x="100" y="198" width="450" height="20.9593" rx="10.4796" fill="#F3F3F3" />
                    <rect x="150" y="249" width="350" height="20.9593" rx="10.4796" fill="#F3F3F3" />
                    <rect x="185" y="293" width="280" height="20.9593" rx="10.4796" fill="#F3F3F3" />
                  </svg>
                </div>
            }
          </div>
        </div>

        <div className="absolute top-0 w-[100%] h-[60px] 2xl:left-[135px]  bg-opacity-40 flex items-center   gap-6 flex-row px-6" >
          <div onClick={() => navigate("/resumes/edit")} className=" md:w-[180px] flex flex-row items-center gap-1 cursor-pointer " >
            <img src="/icons/svg/template.svg" className="h-[12px] md:h-[20px] " />
            <h2 className="  font-poppins text-[9px] xs:text-[12px]  md:text-[15px] text-blue-600 " >Select Template</h2>
          </div>
          <button onClick={handleResetpopup} className="text-blue-600 font-poppins text-[9px] xs:text-[12px]   lg:text-[15px] flex gap-2 items-center cursor-pointer " >
            <img src="/icons/svg/restart.svg" className="h-[12px] md:h-[20px]" />
            Reset
          </button>
          <div className="w-[50%] h-full sm:visible lg:hidden inline-flex items-center justify-end" >
            <img onClick={handleClose} src="/icons/close.svg" className=" h-[30px]  cursor-pointer hover:scale-110 transition-all active:scale-90 " />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
