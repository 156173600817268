import { createSlice } from "@reduxjs/toolkit";
import { current, produce } from "immer";

const initialState = {
  experienceList: JSON.parse(localStorage.getItem("ExperienceList")) || [],
};


export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    addNewExperience: (state, action) => {
      state.experienceList = [...state.experienceList, ""];
    },  
    removeExperience: (state, action) => {
      const position = action.payload;

      const filteredExperienceList = state.experienceList.filter((item,index)=>{
        return position !== index
      })

     state.experienceList = filteredExperienceList
     localStorage.setItem("ExperienceList",JSON.stringify(filteredExperienceList))

    },
    updateExperience: (state, action) => {
      const { position, data } = action.payload;

      const newList =  produce(state, (draft) => {
        if (draft.experienceList && draft.experienceList.length > 0) {
          draft.experienceList[position] = data;
          
        }
      });

      localStorage.setItem("ExperienceList",JSON.stringify(newList.experienceList))

      return newList
      
    },
    
  },
});

// Action creators are generated for each case reducer function
export const { addNewExperience, removeExperience, updateExperience } =
  experienceSlice.actions;

export default experienceSlice.reducer;
