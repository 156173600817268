import React, { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { removeSkill , updateSkill } from '../../../../../../Redux/skills'
import Spinner from "../../../../../../Shared/spinner";



const MySkill = ({index,item}) => {

  const dispatch = useDispatch()


const level = {
    novoice : {
        dark:"#fe7d8b",
        light:"#ffeaec"
    },
    beginner:{
        dark:"#f68559",
        light:"#feebe3"
    },
    skillfull:{
        dark:"#ec930c",
        light:"#fff2cc"
    },
    experienced:{
        dark:"#48ba75",
        light:"#e7f4ed"
    },
    expert:{
        dark:"#9ba1fb",
        light:"#f1f2ff"
    }
}

 const [isActive,setIsActive] = useState(false)
 const [isLoading,setLoading] = useState(false)

 const [skill,setSkill] = useState( item || {
   skill:"",
   skillLevel:"novoice",
   tabPosition:0
 })


 const handleAccordion = () => {
    setIsActive((prev) => !prev);
    };

  const handleInput = (e) => {
    const {value} = e.target;
    setSkill({
      ...skill,
      skill:value
    })
  }


  const handleLevel = (level,position) => {
    setSkill({
      ...skill,
      skillLevel:level,
      tabPosition:position
    })    
  }

  const handleSave = () => {
    setLoading(true); // Set loading to true immediately

    const timeout = setTimeout(() => {
      const payload = {
        position : index,
        data : skill
      }

      dispatch(updateSkill(payload))
      setIsActive((prev) => !prev);
      setLoading(false); // Set loading back to false after the timeout
    }, 2000); // 2 seconds

    return () => clearTimeout(timeout);
  };

  const removeSkillSet = () =>{
     dispatch(removeSkill(index))
  }  

  const disabled = !skill.skill
  
  return (
    <div className='w-full h-auto flex flex-row gap-2  group relative' >
         <div className={`w-[97%] min-h-[60px] max-h-auto flex flex-col gap-2 border-[1px] shadow-sm rounded-sm`} >
            {/**Header**/}
            <div onClick={handleAccordion} className="w-full h-[60px] flex flex-row items-center justify-between px-4 cursor-pointer">
              <div className='flex flex-col gap-1' >
                <h2 className='text-[14px] font-sans font-[600] group-hover:text-[#1A91F0] transition-colors' >{skill.skill ? skill.skill : "(Not Specified)"}</h2>
                <span className="text-[12px] font-sans text-gray-500 capitalize ">
                 {skill.skillLevel}
                </span>
              </div>
              <span
              className={`material-symbols-outlined cursor-pointer group-hover:text-[#1A91F0] transition-colors ${
                isActive ? " rotate-180 " : ""
              } transition-all  `}
            >
              expand_more
            </span>
           </div>
           {/**Body**/}
           <div
            className={`w-full ${
              isActive ? "  " : "h-0 opacity-0 pointer-events-none "
            }   transition-all    `}
          >
            <div className="flex h-auto sm:flex-col md:flex-row sm:gap-4 lg:gap-6  pt-2 pb-3 px-4 ">
             {/***Skills**/}
             <div className='sm:w-full md:w-1/2 flex flex-col gap-1' >
                <h2 className='text-[14px] font-sans text-gray-500 h-[21px] ' >Skill</h2>
                <input onChange={handleInput} value={skill.skill} type='text'  className=' px-4 py-2 bg-[#EFF2F9] h-[40px] rounded-sm outline-none focus:border-[2px] border-b-[#1A91F0]'/>
             </div>
             {/**Level**/}
             <div className='sm:w-full md:w-1/2 flex flex-col gap-1' >
                <div className='h-[21px]' >
                 <span className='text-[14px] font-sans text-gray-500  ' >Level - </span>
                 <span className='text-[14px] font-sans capitalize ' > {skill.skillLevel}</span>
                </div>

                <div style={{background:level[skill.skillLevel]?.light}} className={`h-[40px] rounded-md flex flex-row relative`} >
                  <span style={{position:"absolute",left:`${skill.tabPosition}%`,top:"0",backgroundColor:level[skill.skillLevel]?.dark}} className={` w-[20%] transition-all h-full `} ></span>
                  <span onClick={()=>handleLevel("novoice",0)} className={`w-[20%] h-full hover:bg-[${level.novoice.dark}]  rounded-md cursor-pointer flex items-center`} ></span>
                  <span onClick={()=>handleLevel("beginner",20)} className={`w-[20%] h-full  hover:bg-[${level.beginner.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                  <span onClick={()=>handleLevel("skillfull",40)} className={`w-[20%] h-full  hover:bg-[${level.skillfull.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                  <span onClick={()=>handleLevel("experienced",60)} className={`w-[20%] h-full hover:bg-[${level.experienced.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                  <span onClick={()=>handleLevel("expert",80)} className={`w-[20%] h-full hover:bg-[${level.expert.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                </div>
             </div>

            </div>
            <div className=" flex justify-end item-center mb-2 mx-4  mt-2  ">
                <button
                  disabled={disabled || isLoading }
                  onClick={handleSave}
                  className="bg-[#0a072bfd] hover:bg-[#0a072bdb] disabled:cursor-not-allowed disabled:bg-[#0a072bdb] text-white font-roboto px-6 py-1 rounded-sm  "
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>

          </div>
        </div>
        <span
          onClick={removeSkillSet}
          className="material-symbols-outlined text-gray-200 hidden group-hover:block hover:text-[#1A91F0] cursor-pointer mt-[20px] absolute right-[-20px]  "
        >
          delete
        </span>
    </div>
  )
}

export default MySkill