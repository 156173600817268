import React, { useState } from 'react'
import { setTabCompletion,selectedTab } from "../../../../../Redux/builder";
import { useDispatch, useSelector } from 'react-redux';
import download from '../../../../../assets/download.svg'
import axios from 'axios'
import Spinner from "../../../../../Shared/spinner"

const Download = () => {

  const [isLoading,setLoading] = useState(false)


  const dispatch = useDispatch()

  const state = useSelector((state) => state);
  const currentTemplate = useSelector((state) => state.template.currentTemplate);

  const SERVERURL = process.env.REACT_APP_BASE_URL ;

  const downloadPdf = async () => {

    if (!currentTemplate) {
        return
    }

    setLoading(true)

    const { allSkills, education, experience, myhobbies, profile, mylanguages, template } = state;


    console.log("CLICKED")

    const base64String = localStorage.getItem('profilePic');
    if (base64String) {
        // Convert base64 string to Blob
        const byteCharacters = atob(base64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        // Create a File object from Blob
        const imageFile = new File([blob], 'image.png', { type: 'image/png' });

        const formData = new FormData();
        formData.append('image', imageFile);


        // Send POST request to server
        const res = await axios.post(`${SERVERURL}/api/upload/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (res.data.fileName) {
            let payload = {
                allSkills, education, experience, myhobbies,
                profile,mylanguages,
                profileImage: res.data.fileName,
                templateId: currentTemplate?.name,

            };
            axios({
                method: 'post',
                url: `${SERVERURL}/api/download`,
                data: payload, // you are sending body instead
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(async res => {
                console.log(res);
                const fileUrl = `${SERVERURL}${res.data.file}`;

                const response = await axios.get(fileUrl, {
                    responseType: 'blob'
                });

                setLoading(false)


                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'resume.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
        }
    }

}

  const disabled = isLoading


  return (
    <div className='w-full flex flex-col gap-10 '>
      <div className='w-full h-auto flex flex-col gap-2'>
        <h3  className='text-darkblue sm:text-[18px] xl:text-[22px] font-poppins font-[500] '>
            Download Your Resume
        </h3>
        <p className='text-darkblue text-[15px] font-khula ' >
          We hope our resources have been helpful in crafting your professional profile. Wishing you the best of luck in your career journey!
        </p>
      </div>
      {/* <div>
        <img src={img} alt="" />
      </div> */}
      <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-4 mt-[30px]  ">
          <button disabled={disabled} onClick={downloadPdf} className={`1sm:w-full lg:w-1/2  h-[40px] disabled:cursor-not-allowed text-white font-poppins  flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm  bg-[#0097e6] duration-150 ${!disabled && "hover:bg-[#0096e6b7]" } gap-1   `}>
           {
            isLoading ? <Spinner/> : 
            <>
             <img src={download} alt="" />  
              Download
            </>
           }
          </button>
          <button
           
            className={`sm:w-full lg:w-1/2  h-[40px] text-white font-poppins cursor-pointer flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm bg-[#0097e6] hover:bg-[#0a072bfd]  duration-100    `}
          >
            {" "}
            Change Template
            {" "}
          </button>
        </div>
    </div>
  )
}

export default Download