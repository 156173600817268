import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addHobby } from '../../../../../../Redux/hobbies';

const AddHobbies = () => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    const handleInput = (event) => {
        setInputValue(event.target.value);
    };

    const addNewHobby = () => {
        if (inputValue.trim() !== '') {
            dispatch(addHobby(inputValue.trim()));
            setInputValue('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addNewHobby();
        }
    };

    return (
        <div className='w-full h-auto flex flex-col gap-4'>
            <div className='w-full  flex sm:flex-col md:flex-row gap-2 mt-[20px]'>
                <input
                    onChange={handleInput}
                    value={inputValue}
                    type='text'
                    placeholder="e.g. Cycling, Hockey"
                    className=' sm:w-full md:w-[75%] border-[1px] outline-none border-gray-300 rounded-sm px-4 py-2'
                    onKeyDown={handleKeyPress} // Moved the event listener here
                />
                <button
                    onClick={addNewHobby}
                    className='bg-[#0097e6] lg:text-[14px] hover:bg-[#0a072bfd] px-4 py-2 cursor-pointer uppercase text-white rounded-sm font-alata'
                >
                    Add Hobby
                </button>
            </div>
        </div>
    );
};

export default AddHobbies;
