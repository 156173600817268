import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import styles from '../style'
import { close, menu} from '../assets'
import LinkFaq from './Link'


const Header = () => {

  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate()


  return (
    <div className='bg-white h-[80px] w-full overflow-hidden  z-40'>
      <div className={`${styles.paddingX} flex justify-between items-center mx-auto font-thin text-[18px] max-w-[1280px] `}>
        {/* Logo */}
        <div className='flex font-inter capitalize font-semibold cursor-pointer -ml-2'>
          <img onClick={()=>navigate('/')}  className='w-[180px] -mt-2' src="/quickrezumelogo.png" alt="" />
        </div>

        {/* NavBar Items */}

        <div className='hidden lg:flex '>
          <ul className="flex items-center text-[16px] text-black gap-7 font-medium font-poppins ">
            <Link to='/'><li className='hover:text-[#0097e6] '>Home</li></Link>
            <Link to='/blogs'><li className='hover:text-[#0097e6]'>Blogs</li></Link>
            <Link to='/resume-templates'><li className='hover:text-[#0097e6]'>Templates</li></Link>
            <Link to='/about'><li className='hover:text-[#0097e6]'>About</li></Link>
            <Link to='/contact'><li className='hover:text-[#0097e6] '>Contact Us</li></Link>
            <LinkFaq/>
          </ul>
        </div>

        {/* Create Button of Header */}

        <div className='hidden lg:flex gap-3'>
          <Link to='/resume-templates'>  
          <button className='bg-[#2A84FF] px-3 py-1 rounded-md text-white font-medium text-[16px] uppercase tracking-wider flex justify-center items-center gap-2'>
            Get Started
            {/* <img className="h-5" src={resume} alt="article" /> */}
          </button>
          </Link>
        </div>

        {/* Small Screen Mwnu  */}

        <div className="lg:hidden flex flex-1 justify-end items-center ">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="object-contain w-[35px] h-[35px]"
            onClick={() => setToggle((elem) => !elem)}
          />
          <div
            className={`${toggle ? `flex h-[200px] duration-150 ` : `hidden`
              } small-screen-menu ${styles.paddingX} py-6 bg-white box-shadow absolute z-20 top-[70px] left-0 min-w-full `}
          >
            <ul className={`list-none justify-end items-center flex-1 capitalize text-black text-[20px] font-light `}>
              <Link to='/'><li className='hover:text-sky-500 '>Home</li></Link>
              <Link to='/about'><li className='hover:text-sky-500 '>About</li></Link>
              <Link to='/resume-templates'><li className='hover:text-sky-500 '>Templates</li></Link>
              <Link to='/contact'><li className='hover:text-sky-500 '>Contact</li></Link>
            <LinkFaq/>
              
            </ul>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Header
