import React,{useState} from 'react'
import {useNavigate} from "react-router-dom"
import { useSelector } from "react-redux";
import Spinner from '../../../Shared/spinner';

const Reset = ({resetActive,setisResetActive}) => {

  const navigate = useNavigate()
  const currentTemplate = useSelector((state) => state.template.currentTemplate);
  const [isLoading,setLoading] = useState(false)


    const handleCancel = () => {
        setisResetActive(false)
    }

    const handleReset = () => {

      setLoading(true); // Set loading to true immediately

      const timeout = setTimeout(() => {
        localStorage.removeItem("Skills");
        localStorage.removeItem("ExperienceList")
        localStorage.removeItem("selectedTab")
        localStorage.removeItem("Hobbies")
        localStorage.removeItem("user-profile")
        localStorage.removeItem("Languages")
        localStorage.removeItem("profilePic")
        localStorage.removeItem("PersonalData")
        localStorage.removeItem("Steppers")
        localStorage.removeItem("EducationList")
        navigate(`/resume-builder/${currentTemplate.name}/profile`)
        window.location.reload()
  
        setLoading(false); // Set loading back to false after the timeout
      }, 2000); // 2 seconds
  
      return () => clearTimeout(timeout);
    
    }

    



  return (
    <div className={`w-full h-[100vh] sm:z-[2000] lg:z-[1000] ${resetActive ? "w-full opacity-100  " :" w-0 opacity-0 pointer-events-none " } flex fixed top-0 left-0   items-center justify-center  bg-black bg-opacity-30 backdrop-blur-sm `} >
        <div className={`w-[800px] h-[500px] ${resetActive ? "scale-100 opacity-100":"scale-0 opacity-0 "} transition-all bg-white shadow-md rounded-xl flex flex-col items-center justify-center px-3 py-4 `} >

           <div className='w-[70%] flex items-center justify-center flex-col gap-2' >
                <img src='/Icons/svg/pixeltrue.svg' className='h-[250px]' />
                <h2 className='text-[22px] font-poppins font-semibold ' >Reset All You Personal Data?</h2>
                <p className='text-center text-[15px] ' >This action will reset all your personal data, including personal information, education, experience, skills, and hobbies, allowing you to create a new resume.</p>
                <div className='w-full flex flex-row gap-4 mt-[20px] ' >
                 <button onClick={handleCancel} className='w-1/2 h-[40px] font-poppins text-black border-[1px] hover:bg-[#f7f1f1c0] cursor-pointer active:scale-90  transition-all ' >Cancel</button>
                 <button onClick={handleReset} disabled={isLoading} className='w-1/2 h-[40px] font-poppins bg-blue-500 hover:bg-blue-400 active:scale-90 text-white transition-all cursor-pointer' >
                 {
                  isLoading ? <Spinner/> : "Reset"
                 }
                 </button>
                </div>
           </div>
            

        </div>
    </div>
  )
}

export default Reset