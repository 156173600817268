import React from 'react'
import { Helmet } from 'react-helmet'
import thumbnail from './blog3.jpg'

const Blog3 = () => {

    return (
        <>
            <div className='h-auto flex items-start flex-col gap-8 mt-[40px]  py-3 ' >

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>What is an ATS Friendly Resume & How To Create One? </title>
                    <meta name="An ATS resume is an enhanced version of the normal.resume, tweaked and tailored to be read and processed easily by any Applied Tracking Systems. An ATS refers to the use of software used in making the processes involved in hiring—like sorting, scanning, and ranking—faster and easier for employers and recruiters. The software segregates its ranking according to relevance based on their content relating to a particular job description." />
                    <link rel="canonical" href="blog/how-to-write-an-effective-resume" />
                </Helmet>

                <h2 className='text-[35px] font-poppins text-black font-semibold' >What is an ATS Friendly Resume & How To Create One?</h2>
                <div style={{ backgroundImage: `url(${thumbnail})` }} className='w-full h-[300px] bg-black bg-cover bg-center' ></div>
                <div className=''>
                    <div className='w-full px-2 py-2 flex flex-col gap-10 mt-4'>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <p>An ATS resume is an enhanced version of the normal.resume, tweaked and tailored to be read and processed easily by any Applied Tracking Systems. An ATS refers to the use of software used in making the processes involved in hiring—like sorting, scanning, and ranking—faster and easier for employers and recruiters. The software segregates its ranking according to relevance based on their content relating to a particular job description.</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Simple Formatting:</h3>
                            <p> A simple format excludes complex layouts and avoids, at all costs, graphics, tables, and columns.</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Applicable Keywords:</h3>
                            <p> Use proper keywords and key phrases from the description of the position.
                                Make sure to pay focus on job titles, required skills, and corresponding experience.
                            </p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Standardised Headings: </h3>
                            <p>Use common section headings like "Work Experience," "Education," "Skills," and "Certifications." <br />
                                Do not use creative headings which the ATS might fail to understand. </p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Uniform Formatting:</h3>
                            <p> Bullet points are perfect for lists. <br />
                                Be consistent in your dates and locations.</p>

                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Plain Text:
                            </h3>
                            <p>Avoid using headers, footers, and special characters. <br />
                                Save the resume in plain text format TXT or in a simple DOCX or PDF file.</p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Contact Information:</h3>
                            <p>Put contact information at the top of the resume.
                         Make sure to include an email and phone number</p>

                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[24px] '>How to create ATS-Friendly Resume.</h3>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 1: Choosing a Clean Template</h3>
                            <p>Select an ATS-friendly resume template. Most of the free resume builders have built-in templates designed for passing through the ATS system. i-e <a href="https://quickrezume.com" className='text-blue-500'>https://quickrezume.com</a> </p>
                            
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 2: Adding Keywords</h3>
                            <p>Scan the description for key words and phrases. Then, incorporate those keywords naturally into your resume. </p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 3: Standardize Section Headings</h3>
                            <p>Use the following common headings to organize your resume content:</p>
                            <p>. Contact Information</p>
                            <p>. Summary</p>
                            <p>. Work Experience</p>
                            <p>. Education</p>
                            <p>. Skills</p>
                            <p>. Certifications</p>
                            <p>. Projects</p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 4: Work Experience Optimization</h3>
                            <p>List your work experience in reverse order that is, starting with the most recent position you have held. Use some bullet points to explain your job responsibilities and work experience. Be sure to include targeted keywords in your explanations.</p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 5: Bring Out the Skills</h3>
                            <p>Skills Section: Mention all types of technical and soft skills relevant for the job. Ensure that you include a selection of keywords matching the description of your job..</p>
                        </div>


                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 6: Removing the non-ATS-Friendly Features</h3>
                            <p>There should be no images, graphics, or charts.
                                Do not use columns or tables.
                                Make sure that there are no special characters or fonts that are hard to read in your resume. <br /> We have already created an <a href="https://quickrezume.com/resume-templates" className='text-blue-500'> ATS-Friendly templates </a>. You can use our free resume builder tool to make Ats friendly resume. </p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>Step 7: Proofread and Save</h3>
                            <p>Proof for errors or inconsistencies. Save your resume as plain text or as a plain DOCX or PDF document.</p>
                        </div>


                        <div className='font-poppins' >
                            <h3 className='text-[20px] '>Conclusion:  </h3>

                            <p>An ATS-friendly resume increases your visibility with potential employers by making sure that your resume can either be easily read or processed by software. Following the above guidelines, you will write a resume that will ensure effective highlighting of your qualifications and help you get through the initial screening.</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default Blog3