import React from 'react'
import { Link } from 'react-scroll';

const LinkFaq = () => {
  return (
    <div>
       <Link
            activeClass="active"
            to="faq"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
            ><li className='outline-none list-none '>FAQ</li></Link>
    </div>
  )
}

export default LinkFaq
