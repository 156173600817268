import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addLanguage } from '../../../../../../../Redux/languages';
import AddLanguages from './addLanguages';


const Languages = () => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.mylanguages.languages)

    const AddNew = () => {
        dispatch(addLanguage())
        console.log("Added")
    }

    return (
        <div>
            <div className='w-full h-auto flex flex-col gap-4' >
                {
                 language &&  language.length > 0 ?
                  language.map((item,index)=>{
                    return (
                        <AddLanguages key={index} index={index} item={item}  />
                    )
                    })
                    :""

                }
                <div
                    onClick={AddNew}
                    className="w-[97%] flex flex-row gap-2 items-center hover:bg-[#1a90f01c] cursor-pointer py-2 px-2 transition-all rounded-sm "
                >
                    <span className="material-symbols-outlined text-[#1A91F0] text-[20px] font-[600] ">
                        add
                    </span>
                    <span className="text-[#1A91F0] text-[14px] font-poppins font-[600] tracking-[0.3px] ">
                        {language && language.length > 0
                            ? "Add one more Language"
                            : "Add Language"}
                    </span>
                </div>

            </div>
        </div>
    )
}

export default Languages
