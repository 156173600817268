import React from 'react'
import Header from '../../../Shared/Header'
import Footer from '../../../Shared/Footer'
import { Helmet } from 'react-helmet'

const TermConditions = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms & Conditons</title>
                <link rel="canonical" href="/terms&conditions" />
            </Helmet>
            <Header />
            <div className='w-full mx-auto max-w-[1280px] px-12 '>
                <div className='w-[full] h-[32vh] bg-blue-400 mb-[48px]  flex flex-col justify-center items-center '>
                    <h2 className='text-[24px] text-white font-poppins font-semibold'>Terms & Conditions</h2>
                </div>
                <div className='w-full flex flex-col gap-4 py-[48px]'>
                    <h2 className='text-[18px] font-inter font-semibold'><strong>Terms and Conditions</strong></h2>

                    <p className='text-[16px] font-inter'>Welcome to Quick Rezume!</p>

                    <p className='text-[16px] font-inter'>The guidelines for using the https://quickrezume.com, Quick Rezume website are described in these terms and conditions.</p>

                    <p className='text-[16px] font-inter'>We are so glad you are here before you begin using our services please take a moment to read through our terms & conditions. We have attempted to make them short and easy to understand.
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'><strong>1. Acceptance of Terms</strong></h3>

                    <p className='text-[16px] font-inter'>Acceptance of the terms stated above is signified by using Quick Rezume. If you do not agree please do not use our service.
                    </p>


                    <h3 className='text-[18px] font-inter font-semibold'><strong>2. Your Account</strong></h3>

                    <p className='text-[16px] font-inter'>Account Creation: To use Quick Rezume you need to register an account with the service provider. The login credentials must be protected, it cannot be shared with anybody.
                        Your Information: The information that you provide need to be accurate and genuine. Please move it if it changes.</p>


                    <h3 className='text-[18px] font-inter font-semibold'><strong>3. Use of Quick Rezume</strong></h3>

                    <p className='text-[16px] font-inter'>Personal Use: To be specific, the purpose of this work called Quick Rezume is individual. Please do not use it commercially until such time that you are given permit to do so by us.
                        Content Ownership: When you input data to Quick Rezume you are the owner of those inputs where ever they may be placed. Thus, we have never tried to own your resume data in the traditional sense.
                        Our License to You: We hereby give a limited license to use Quick Rezume only for creating resumes and/or downloading the same.</p>



                    <h3 className='text-[18px] font-inter font-semibold'><strong>4. Privacy</strong></h3>

                    <p className='text-[16px] font-inter'>Your Privacy: As for the respect of your right for private life, we do respect it as well. You are kindly invited to read about the <a href="https://quickrezume.com/privacy-policies" className='text-blue-500'>Privacy Policy</a>  as to the ways we collect and utilize your data.</p>

                    <h3 className='text-[18px] font-inter font-semibold'><strong>5. Your Responsibilities</strong></h3>

                    <p className='text-[16px] font-inter'>Lawful Use: For violating the above rules You agree will not use Quick Rezume for any unlawful purposes. With Quick Rezume, bad is anything related to the commission of an unlawful and damaging task.
                        Respectful Use: You will be courteous to other people; you shall not interfere with other people’s business.
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'><strong>6. Changes to the Service</strong></h3>

                    <p className='text-[16px] font-inter'>Quick Rezume never stands still, we are always enhancing it. We can also cause the addition or removal of features and will endeavour to provide users with fairly adequate notice concerning these.</p>

                    <h3 className='text-[18px] font-inter font-semibold'><strong>7. Termination</strong></h3>

                    <p className='text-[16px] font-inter'>Thank you for using Quick Rezume However, we reserve the right to suspend/terminate your account in the event of your violation of these terms and conditions.</p>


                    <h3 className='text-[18px] font-inter font-semibold'><strong>8. Disclaimers</strong></h3>

                    <p className='text-[16px] font-inter'>As-Is Service: Quick Rezume is provided strictly “AS IS. ” We provide no warranty or guarantee related to the service. <br />
                        Limitation of Liability: As for the warranties Quick Rezume may include, they don’t cover any damages that may arise from the use of the software.

                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'><strong>9. Changes to These Terms</strong></h3>

                    <p className='text-[16px] font-inter'>The conditions outlined here may be changed from time to time. If there are more principal changes we definitely notify you. That means any changes regarding the website or the service that Quick Rezume provides to its users are to be accepted when you keep on using the service.

                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'><strong>10. Contact Us</strong></h3>

                    <p className='text-[16px] font-inter'>
                    If you have any questions or do not understand something about these terms, you can always address to us.
                    </p>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TermConditions
