import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BoltIcon from '@mui/icons-material/Bolt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AllTemplates } from '../Templates/templates';
import { Copy, Briefcase, Sparkles, Zap } from 'lucide-react'



const SlideTab = ({handlefilterItem, setFilterItem,handleAll }) => {

const [activeTab, setActiveTab] = useState(0);


  const tabs = [
    { id: 'all', label: 'ALL TEMPLATES', icon: Copy,onClick: () => handleAll('all')},
    { id: 'ats', label: 'ATS-FRIENDLY', icon: Briefcase, onClick: () => handlefilterItem('Ats') },
    { id: 'simple', label: 'SIMPLE', icon: Sparkles, onClick: () => handlefilterItem('Simple') },
    { id: 'modern', label: 'MODERN', icon: Zap, onClick: () => handlefilterItem('Modern') },
  ];



  const handleChange = (event, filterFunction) => {
    setActiveTab(event);
    handlefilterItem()
  };

  return (
    <div className="w-full bg-white border-b border-gray-200">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-center">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => {
                handleChange(index);
                tab.onClick();
              }}
              
              className={`flex items-center px-4 py-2 text-[8px] md:text-lg font-medium transition-all duration-300 ${
                activeTab === index 
                ? 'text-blue-600 border-b-2 border-blue-600' 
                : 'text-gray-500 hover:text-gray-700 border-b-2 border-transparent'
              }`}
            >
              <tab.icon className="w-5 h-5 mr-2" />
              {tab.label}
            </button>
          ))}
        </div>
      </div>
    </div>


    // <Box sx={{ width: '100%', bgcolor: 'background.paper',}}  >
    //   <Tabs  sx={{ fontSize : "18px" }} value={value} onChange={handleChange} centered>
    //     <Tab onClick={()=>handleAll()}  sx={{ fontSize : "18px" }} icon={<ContentCopyIcon fontSize='medium'/>} iconPosition='start' label="All Templates" />
    //     <Tab onClick={()=> handlefilterItem("Ats")} sx={{ fontSize : "18px" }} icon={<BusinessCenterIcon/>} iconPosition='start' label="Ats-Friendly" />
    //     <Tab onClick={()=>handlefilterItem("Simple")} sx={{ fontSize : "18px" }} icon={<AutoAwesomeIcon/>} iconPosition='start' label="Simple" />
    //     <Tab onClick={()=>handlefilterItem("Modern")} sx={{ fontSize : "18px" }} icon={<BoltIcon/>} iconPosition='start' label="Modern" />
    //   </Tabs>
    // </Box>
  );
}

export default SlideTab