import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedTab,
  setTabCompletion,
} from "../../../../../Redux/builder";
import "./tabs.css";
import { useNavigate } from "react-router-dom";

const Circle = ({ text, tab, isCompleted, route }) => {
  const Navigate = useNavigate();

  const dispatch = useDispatch();

  const Steppers = useSelector((state) => state.builder.StepperTabs);
  const activeTab = useSelector(state=>state.builder.selectedTab)
  const currentTemplate = useSelector((state)=> state.template.currentTemplate)




  const handleTabs = () => {

    if (tab == "Profile" && isCompleted ) {
      Navigate(`/resume-builder/${currentTemplate.name}/${route}`);
      dispatch(setTabCompletion({ tabName: tab, completed: true }));
      dispatch(selectedTab(tab))
    }
    else if(tab == "Education" && isCompleted ){
        Navigate(`/resume-builder/${currentTemplate.name}/${route}`);
        dispatch(setTabCompletion({ tabName: tab, completed: true }));
        dispatch(selectedTab(tab))
        console.log("Education")
    }
    else if(tab == "Experience" && isCompleted){
      Navigate(`/resume-builder/${currentTemplate.name}/${route}`);
       dispatch(setTabCompletion({ tabName: tab, completed: true }));
       dispatch(selectedTab(tab))
    }
    else if(tab =="Skills" && isCompleted ){
      Navigate(`/resume-builder/${currentTemplate.name}/${route}`);
      dispatch(setTabCompletion({ tabName: tab, completed: true }));
      dispatch(selectedTab(tab))
    }
    else if(tab == "Hobbies" && isCompleted ){
      Navigate(`/resume-builder/${currentTemplate.name}/${route}`);
      dispatch(setTabCompletion({ tabName: tab, completed: true }));
      dispatch(selectedTab(tab))
    }
    else if(tab == "Download" && isCompleted ){
      Navigate(`/resume-builder/${currentTemplate.name}/${route}`);
      dispatch(setTabCompletion({ tabName: tab, completed: true }));
      dispatch(selectedTab(tab))
    }
    
  }

  return (
    <div
      onClick={handleTabs}
     
      className={`w-auto h-[auto] flex flex-row cursor-pointer  items-center child justify-center   ${
        isCompleted || activeTab == tab  ? "active" : "de-active"
      }   `}
    >
      <div className="md:w-[38px]  h-auto flex flex-col items-center sm:hidden md:flex -mt-1">
        {/**Circle */}
        <div
          style={{backgroundColor:activeTab == tab && "#0097e6" }}
          className={`md:w-[34px] md:h-[34px]   ${
            isCompleted || activeTab == tab  
              ? "bg-darkblue shadow-active "
              : "bg-[#D0D2D4] shadow-deActive "
          }   rounded-full flex items-center justify-center  `}
        >
          <p className="text-white font-alata md:text-[18px] flex items-center justify-center  ">
            {text}
          </p>
        </div>
        {/**Text */}
        <h2
          className={`font-khula ${
            isCompleted  || activeTab == tab 
              ? "text-darkblue"
              : " text-[#D0D2D4]"
          }  md:text-[12px] 2xl:text-[16px] mt-2 `}
        >
          {tab}
        </h2>
      </div>
    </div>
  );
};

export default Circle;
