import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules'
import { AllTemplates } from '../../Templates/templates'
import Template from './Template.svg'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './Slider.css'



const Slider = () => {
    return (
        <div className="container ">
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container lg:w-[950px]"
            >
                {AllTemplates.map((item) => {
                    return (
                        <SwiperSlide>
                            <img src={item.image} alt="slide_image" />
                        </SwiperSlide>
                    )
                })}
                {/* <div className="slider-controler">
                    <div className="swiper-button-prev slider-arrow">
                        <span class="material-symbols-outlined">
                            arrow_back_ios
                        </span>
                    </div>
                    <div className="swiper-button-next slider-arrow">
                    <span class="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                    <div className="swiper-pagination"></div>
                </div> */}
            </Swiper>
        </div>
    )
}

export default Slider
