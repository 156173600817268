import React from 'react'
import DataEducation from "./dataEducation"

const Education = () => {
  return (
    <div className='w-full h-auto flex flex-col gap-4' >
      {/**Heading Text wrapper**/}
      <div className='w-full h-auto flex flex-col gap-2' >
        <h2 className='text-darkblue sm:text-[18px] xl:text-[22px] font-poppins font-[500]  ' >Tell us about your education</h2>
        <p className='text-darkblue text-[14px] font-khula ' >A varied education on your resume sums up the value that your learnings and background will bring to join.</p>
      </div>
      {/**Form Wrapper**/}
       <DataEducation/>
      {/* <div className='w-full h-[50px] block ' >

      </div> */}
    </div>
  )
}

export default Education