import React from "react";
import Tabs from "./Tabs/tabs";
import { Outlet, Link } from "react-router-dom";
import './index.css'

const FormBuilder = () => {
  return (
    <div className="sm:w-full lg:w-[50%] formBuilder h-full flex flex-col md:items-center  gap-4 bg-gray-50 shadow-lg overflow-y-auto scroll_bar  ">
      {/***Tabs */}

      <div className="sm:w-full md:w-full h-full  2xl:w-[80%] flex flex-col max-w-2xl xl:mx-auto sm:px-8 md:px-8 pt-1 w-[100%]">
        <Link to={'/'}>
        <button className="flex items-center gap-2 bg-[#fefefe] shadow-lg text-[15px]  w-[100px] px-4 my-2 text-slate-700 uppercase font-inter py-1 rounded-full hover:bg-[#e5e5e5]">
          <img className="w-[13px]"  src="/icons/svg/home.svg" alt="" />
          Home
          </button>
        </Link>
        <Tabs />
        <div className="w-full 2xl:w-[] h-[calc(100%-70px)] mt-[30px]   ">
          <Outlet />
        </div>


      </div>
    </div>
  );
};

export default FormBuilder;
