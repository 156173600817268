import React, { useRef, useState } from "react";
import cheron from './cheron.svg'

const Faq = ({ Title, Enabled,desc }) => {
  let ContainerHeight = "min-h-[60px]";
  let backgroundColor = "bg-gradient-to-r  from-greenish to-yellowish";

  const [Expand, setExpand] = useState(Enabled);

  let HandleExpand = () => {
    setExpand(Expand == true ? false : true);
  };

  !Expand ? (ContainerHeight = "h-[60px]") : (ContainerHeight = "md:h-[200px] sm:h-[250px]");

  !Expand
  ? (backgroundColor = "bg-white")
    : (backgroundColor = " bg-[#2A84FF] ")

  return (
    <>
      <div
      
        className={`sm:w-[90%] md:w-[85%] ${ContainerHeight} flex items-center  transition-all  mx-auto rounded-[5px] p-1`}
      >
        <div
          className={`w-full h-full flex  flex-col transition-all justify-between mx-auto  box_shadow shadow-sm   p-2  `}
        >
          <div  onClick={HandleExpand} className="w-full h-[40px] cursor-pointer flex flex-row justify-between px-[15px] ">
            <div className="w-[86%] h-full flex items-center">
              <span
                className={` sm:text-[15px] md:text-[18px] text-left text-black font-Inter font-[500] tracking-wide `}
              >
                {Title}
              </span>
            </div>

            <div className="w-[10%] h-full flex items-center justify-end ">
              {
                <img onClick={HandleExpand}  src={cheron} className={`h-[14px] cursor-pointer ${!Expand ? "rotate-0" : "rotate-180"}  `}  />
              }
             
            </div>
          </div>

          <div
            className={`w-full ${
              Expand ? "h-[64%]" : "h-[0px]"
            }  m-auto overflow-hidden  flex px-[15px]  `}
          >
            <p className={`text-[16px] text-left  text-${Expand ? "black" : "white"} `}>
             {desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;