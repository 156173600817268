import React from 'react'

const MainText = () => {
  return (
    <div style={{boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}} className="w-full flex flex-col gap-4 mx-auto font-poppins max-w-[1280px] px-10 py-10 my-12">
      <h1 className='text-[22px]'>Welcome to <span className='text-[#2A84FF]'>Quick Rezume</span></h1>
      <h2 className='text-[16px]'>Build Your Perfect Resume in Minutes</h2>
      <p className='text-[14px]'>At Quick Rezume, we have a variety of products available for you to choose from, but getting ready for the next step in your career is easy here. Everything a pro detailer and a rookie-demanding debutant would get out of it is in one plain and simple-dash. The all-inclusive do-it-yourself setup of the essays serves the causes of knowledge and the flair of personal experience at the same time.</p>
      <h1 className='text-[22px]'>Why <span className='text-[#2A84FF]'>Quick Rezume</span> ?</h1>
      <p className='text-[14px]'> <span className=' font-semibold'>Friendly User Interface: </span> Our detailed advice ensures a smooth and error-free CV creation process.</p>
      <p className='text-[14px]'><span className=' font-semibold'>Professional Templates:</span> Get a selection of modern, browser-accessible templates that can transform your resume with a whole new look and feel.</p>
      <p className='text-[14px]'><span className='font-semibold'>Customization Options:</span> Personalize your resume through your ideas and future plans for the career.</p>
      <p className='text-[14px]'><span className='font-semibold'>Instant Download: </span> Generate, revise, and save in different file formats such as PDF, Word, and so many other options.</p>
      <p className='text-[14px]'> <span className='font-semibold'>24/7 Support: </span> Have some questions or need help? Our support team is there for you at any time of the day. </p>
      <h1 className='text-[22px]'>How It <span className='text-[#2A84FF]'>Works</span></h1>
      <p className='text-[14px]'> <span className='font-semibold'>Pick a Template: </span> Start by choosing one of our templates that are professionally designed and that align with your industry and level of experience.  </p>
      <p className='text-[14px]'> <span className='font-semibold'> Fill in Your Details: </span> Then go through our software that will lead you with guidelines on how to fill in all your personal information, work experience, education, and skills.  </p>
      <p className='text-[14px]'> <span className='font-semibold'>Customize and Optimize: </span> Make it a resume you love by personalizing it with your own chosen fonts, colors, and layout. And, also, incorporate been-the-best-works-session-in-the-rocketsuccess advice to polish your resume till you crack the job interview!  </p>
      <p className='text-[14px]'> <span className='font-semibold'> Download and Share:</span>Once you are done with the résumé, save it in the format you like best and start sending your applications!  </p>
      <h1 className='text-[22px]'>Get Your Resume Now!</h1>
        <p className='text-[14px]'>Thousands of users have realized their dreams of getting their dream jobs with Quick Rezume. The first thing you should do to begin your dream job is to use our services, and get your resume done with us. It's Fast, Easy and Absolutely Free!</p>
    </div>
  )
}

export default MainText
