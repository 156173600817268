import { createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";

const initialState = {
  skills: JSON.parse(localStorage.getItem("Skills")) || [],
};

export const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    addSkill: (state, action) => {
      state.skills = [...state.skills, ""];
    },

    removeSkill: (state, action) => {
      const position = action.payload
      const filteredSkills = state.skills.filter((item, index) => {
        return position !== index;
      });

      localStorage.setItem("Skills",JSON.stringify(filteredSkills))
      state.skills = filteredSkills;
    },

    updateSkill: (state, action) => {
      const { position, data } = action.payload;

      const newSkill = produce(state, (draft) => {
        if (draft.skills && draft.skills.length > 0) {
          draft.skills[position] = data;
        }
      });

      const updatedSkills = newSkill.skills;

      localStorage.setItem("Skills",JSON.stringify(updatedSkills))

      return newSkill;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSkill, removeSkill, updateSkill } = skillsSlice.actions;

export default skillsSlice.reducer;
