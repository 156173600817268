import React from 'react'
import Form from './form'

const Profile = () => {
  return (
    <div className='w-full h-auto flex flex-col gap-4' >
      {/**Heading Text wrapper**/}
      <div className='w-full h-auto flex flex-col gap-2' >
        <h2 className='text-darkblue sm:text-[18px] xl:text-[22px] font-poppins font-[500] ' >What’s the best way for employers to contact you ?</h2>
        <p className='text-darkblue text-[14px] font-khula ' >We suggest including an email and phone number.</p>
      </div>
      {/**Form Wrapper**/}
      <Form/>
      <div className='w-full h-[50px] block ' >

      </div>
    </div>
  )
}

export default Profile