import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Profile_Pic from "./profile-pic";
import "./profile.css";
import { setProfileData } from "../../../../../../Redux/profile";
import {
  setTabCompletion,
  selectedTab
} from "../../../../../../Redux/builder";
import Languages from "./Languages/Languages";

const Form = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ProfileData = useSelector((state)=>state.profile.personalData)
  const currentTemplate = useSelector((state)=> state.template.currentTemplate)


  const [isError, setisError] = useState(false);
  const [personalData, setPersonalData] = useState(
    // JSON.parse(localStorage.getItem("PersonalData")) ||
   {
      profilePic: "",
      imgFile:"",
      title : "", 
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      summary:"",
    }
  );

  console.log(personalData)

  // useEffect(() => {
  //   const profileData = JSON.parse(localStorage.getItem("PersonalData"));
  //    dispatch(setProfileData(personalData))
  // }, [personalData]);


  useEffect(()=>{
    setPersonalData(ProfileData)
  },[ProfileData])

  const handleInputs = (event) => {
    const { value, name } = event.target;

    setPersonalData({
      ...personalData,
      [name]: value,
    });
  };

  const error =
    !personalData.profilePic ||
    !personalData.title ||
    !personalData.firstName ||
    !personalData.lastName ||
    !personalData.email ||
    !personalData.phone ||
    !personalData.country ||
    !personalData.city ||
    !personalData.summary ;

  const handleNext = () => {
    if (error) {
      setisError(true);
    } else {
      setisError(false);
      dispatch(setProfileData(personalData));
      localStorage.setItem("PersonalData", JSON.stringify(personalData));
      dispatch(setTabCompletion({ tabName: "Profile", completed: true }));
      dispatch(selectedTab("Education"))
      navigate(`/resume-builder/${currentTemplate.name}/education`);
      dispatch(setProfileData(personalData));
    }
  };

  const handleSave = () => {
    if (error) {
      setisError(true);
    } else {
      setisError(false);
      dispatch(setProfileData(personalData));
      localStorage.setItem("PersonalData", JSON.stringify(personalData));
    }
  }

  return (
    <>
      <div className="w-full h-auto flex flex-col gap-6">
        {/**Profile Picture**/}
        <div className="md:flex gap-2 items-center font-poppins">

          <Profile_Pic
            setPersonalData={setPersonalData}
            personalData={personalData}
          />
          <div className="sm:w-full md:w-[80%] lg:w-1/2 flex flex-col gap-1 wrapper mt-4 md:mt-0  ">
            <label className=" label  ">Title</label>
            <input
              onChange={handleInputs}
              name="title"
              type="text"
              value={personalData.title}
              required
              placeholder="e.g. Front-End Developer"
              className="input px-4 py-4 h-[40px]  "
            />
          </div>
        </div>
        {/**Full Name Input**/}
        <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-6  pt-2 ">
          {/*First NAme*/}
          <div className="sm:w-full lg:w-1/2 flex flex-col gap-1 wrapper   ">
            <label className=" label  ">First Name</label>
            <input
              onChange={handleInputs}
              name="firstName"
              type="text"
              value={personalData.firstName}
              required
              placeholder="e.g. Jhon"
              className="input px-4 py-4 h-[40px]  "
            />
          </div>
          {/*Last Name*/}
          <div className="sm:w-full lg:w-1/2 flex flex-col gap-1 wrapper   ">
            <label className=" label  ">Last Name</label>
            <input
              onChange={handleInputs}
              required
              name="lastName"
              type="text"
              value={personalData.lastName}
              placeholder="e.g. Smith"
              className="input px-4 py-4  h-[40px] "
            />
          </div>
        </div>
        {/**Email Inputs**/}
        <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-6  ">
          {/*Email*/}
          <div className="sm:w-full lg:w-1/2 flex flex-col gap-1 wrapper   ">
            <label className=" label  ">Email</label>
            <input
              required
              type="text"
              onChange={handleInputs}
              value={personalData.email}
              name="email"
              placeholder="e.g. example@gmail.com"
              className="input px-4 py-4 h-[40px]  "
            />
          </div>
          {/*Phone*/}
          <div className="sm:w-full lg:w-1/2 flex flex-col gap-1 wrapper   ">
            <label className=" label  ">Phone</label>
            <input
              type="text"
              required
              onChange={handleInputs}
              value={personalData.phone}
              name="phone"
              placeholder="e.g. +123 456 789"
              className="input px-4 py-4 h-[40px]  "
            />
          </div>
        </div>
        {/**Country Inputs**/}
        <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-6  ">
          {/*Email*/}
          <div className="sm:w-full lg:w-1/2 flex flex-col gap-1 wrapper   ">
            <label className=" label  ">Country</label>
            <input
              required
              type="text"
              onChange={handleInputs}
              value={personalData.country}
              name="country"
              placeholder="e.g. Pakistan"
              className="input px-4 py-4 h-[40px]  "
            />
          </div>
          {/*Phone*/}
          <div className="sm:w-full lg:w-1/2 flex flex-col gap-1 wrapper   ">
            <label className=" label  ">City</label>
            <input
              type="text"
              required
              onChange={handleInputs}
              value={personalData.city}
              name="city"
              placeholder="e.g. Islamabad"
              className="input px-4 py-4  h-[40px] "
            />
          </div>
        </div>

        {/**Professional Summary**/}
        <div className="w-100% h-[auto] flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-6 mt-5  ">
          <div className="w-full flex flex-col gap-1 wrapper ">
            <label className=" label text-[18px] mb-2 text-black ">Professional Summary</label>
            <textarea
              type="text"
              required
              onChange={handleInputs}
              value={personalData.summary}
              name="summary"
              placeholder="e.g. A self_taught web developer with 3+ years experience & ....... "
              className="input px-4 py-4 h-[200px] overflow-y-auto resize-none w-full  "
            />
          </div>
        </div>

        <div>
          <Languages/>
        </div>


        {isError && (
          <span className="w-full text-center font-khula text-red-700">
            Can not Proceed While Some Field are Empty{" "}
          </span>
        )}

        {/**Button**/}

        {/* <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-4 mt-[30px]  ">
        <button className="w-full h-[40px]  text-black font-poppins  flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm  hover:bg-[#0097e6] hover:text-white duration-150    ">
            {" "}
            Save{" "}
          </button>
        </div> */}

        <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-4 mt-[30px]  ">
          <button onClick={handleSave} className="sm:w-full lg:w-1/2  h-[40px] text-black font-poppins  flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm  hover:bg-[#0097e6] hover:text-white duration-150    ">
            {" "}
            Save{" "}
          </button>
          <button
            onClick={handleNext}
            className={`sm:w-full lg:w-1/2  h-[40px] text-white font-poppins cursor-pointer flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm bg-[#0097e6] hover:bg-[#0a072bfd] duration-100    `}
          >
            {" "}
            Next{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Form;
