import React, { useEffect, useState } from 'react'
import { useParams,Outlet } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Header from '../../Shared/Header';
import Footer from '../../Shared/Footer';

const Blog = () => {

    

  return (
    <div className="w-full min-h-[100vh]  overflow-x-hidden ">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Blog Page</title>
            <link rel="canonical" href="/blog-page" />
         </Helmet>
        
      <div className="w-full ">
        {/**Header**/}
        <div className=" ">
       <Header/>
        </div>
        <div className="mx-auto w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-full h-[100%] max-w-6xl py-[24px] flex flex-col items-start ">
          <Outlet/>
        </div>
        <Footer/>
      </div>
    </div>
  )
}

export default Blog