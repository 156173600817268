import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Circle from "./circle";
import "./tabs.css";
import { useFetcher } from "react-router-dom";
import { setTabCompletion } from "../../../../../Redux/builder";

const Tabs = () => {
  const dispatch = useDispatch();

  const Steppers = useSelector((state) => state.builder.StepperTabs);

  useEffect(() => {
    localStorage.setItem("Steppers", JSON.stringify(Steppers));
  },[]);

  return (
    <div className="w-full h-[auto] py-4 flex flex-row sm:flex-wrap  lg:flex-nowrap items-center wrapper sm:gap-4 md:gap-0 sticky top-0 bg-gray-50 z-20 ">
      {/***Tab Wrapper*/}
      {Steppers.map((item, index) => {
        return (
          <Circle
            item = {item}
            key={index}
            text={item.text}
            tab={item.Stepper}
            isCompleted={item.status}
            route={item.route}
          />
        );
      })}
    </div>
  );
};

export default Tabs;
