import React,{useRef} from 'react'
import Faq from './Faq'

const FaqSection = () => {

  const FaqRef = useRef(null)


  return (
    <div ref={FaqRef} id='faq' className='w-full h-auto mb-[80px] flex items-center flex-col gap-6 mt-[40px] sm:w-full md:w-[90%] xl:w-[80%] max-w-6xl mx-auto ' >
      <h2 className='text-[40px] text-black font-Inter font-semibold text-center  ' >
        Frequently Ask
         <span className='text-[#2A84FF] p-2  ' >
            Questions
         </span>
      </h2>

      <div className="w-full h-[80%] text-black  gap-3  flex flex-col p-3 border-greenish ">
      <Faq Title={"What is Quick Rezume?"}
       desc={"A Quick Rezume is an online tool that helps users create professional resumes quickly and easily. It typically provides templates, formatting options, and guidance to organize and highlight skills, experience, and qualifications effectively."} Enabled={true} />
          <Faq
            Title={"How does your free resume builder work?"}
            Enabled={false}
            desc={"This free resume builder requires only that you select a template and then start typing in your details. The creator will walk you through each section to make a professional resume."}
          />
          <Faq
            Title={"What are some of the templates that your resume builder has?"}
            Enabled={false}
            desc={"A variety of templates focus on different industries/career levels. From fresh graduates to seasoned professionals and those changing careers, you will find the templates that give the best presentation for your skills and experience."}
          />
          <Faq
            Title={"Is there a limit as to the number of resumes I can create using your platform?"}
            Enabled={false}
            desc={"No, there is no limit to how many resumes our system allows one to create: applying for different positions, tweaking your resume to specific employers, or just searching for new career opportunities—you are able to create and easily manage multiple resumes anytime."}
          />
        </div>
    </div>
  )
}

export default FaqSection