import LondonTemplate from "./LondonTemplate/LondonTemplate.png";
import RomeTemplate from "./RomeTemplate/RomeTemplate.png";
import TokyoTemplate from "./TokyoTemplate/TokyoTemplate.png";
import ParisTemplate from "./ParisTemplate/ParisTemplate.png";
import TorontoTemplate from "./TorontoTemplate/TorontoTemplate.png";
import BerlinTemplate from "./BerlinTemplate/BerlinTemplate.jpg"
import Budapest from './BudapestTemplate/budapest.jpg'

export const AllTemplates = [
  {
    name: "TokyoTemplate",
    title: "Tokyo ",
    path: "/TokyoTemplate",
    image: TokyoTemplate,
    category: "Ats",
  },
  {
    name:"RomeTemplate",
    title:"Rome",
    path:"/RomeTemplate",
    image: RomeTemplate,
    category : "Modern"
  },
    {
      name:"LondonTemplate",
      title :"London",
      path:"/LondonTemplate",
      image: LondonTemplate,
      category : "Simple"
    },
    
    {
      name:"ParisTemplate",
      title:"Paris",
      path:"/ParisTemplate",
      image: ParisTemplate ,
      category : "Simple"
    },
    {
      name:"TorontoTemplate",
      title:"Toronto",
      path:"/TorontoTemplate",
      image: TorontoTemplate ,
      category : "Modern"
     
    },
    {
      name:"BerlinTemplate",
      title:"Berlin",
      path:"/BerlinTemplate",
      image: BerlinTemplate ,
      category : "Simple"
     
    },
    {
      name:"BudapestTemplate",
      title:"Budapest",
      path:"/BudapestTemplate",
      image: Budapest ,
      category : "Modern"
     
    },
   
]
