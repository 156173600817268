import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "./Routes/index";
import Home from "./modules/Home/index";
import { store } from "./store";
import { Provider } from "react-redux";
import About from "./modules/About/About";
import Template from "./modules/ResumeTemplate/Template";
import Contact from "./modules/Contact/Contact";
import Blogs from "./modules/Blogs/Blogs";
import Blog from "./modules/Blogs";
import Blog1 from "./modules/Blogs/articles/Blog1/blog1";
import Blog2 from "./modules/Blogs/articles/Blog2/blog2";
import PrivacyPolicy from "./modules/Pages/PrivacyPolicy";
import Disclaimer from "./modules/Pages/Disclaimer";
import TermConditions from "./modules/Pages/Terms&Conditions";
import Blog3 from "./modules/Blogs/articles/Blog3/blog3";
import ScrollToTop from "./Shared/ScrollTop";


const App = () => {
  return (
    <div>
      <Provider store={store}>
        <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume-templates" element={<Template />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/blogs" element={<Blogs/>} />
          <Route path="/privacy-policies" element={<PrivacyPolicy/>}/>
          <Route path="/disclaimer" element={<Disclaimer/>}/>
          <Route path="/terms&conditions" element={<TermConditions/>}/>
          <Route path="/blog/*" element={<Blog/>}>
          <Route path='how-to-write-an-effective-resume' element={<Blog1/>} />
          <Route path='common-resume-mistakes-to-avoid' element={<Blog2/>} />
          <Route path='what-is-an-ATS-Friendly-resume' element={<Blog3/>} />
          </Route>
          {ROUTES.map(({ path, component: Component, children }, index) => (
            <Route key={index} path={path} element={<Component/>}>
              {children?.map(
                ({ path, component: ComponentChild }, childIndex) => (
                  <Route
                    key={childIndex}
                    path={path}
                    element={<ComponentChild/>}
                  />
                )
              )}
            </Route>
          ))}
        </Routes>
      </ScrollToTop>
      </Provider>
    </div>
  );
};

export default App;
