import React, { useEffect, useState } from "react";
import "./index.css";
import FormBuilder from "./Form";
import Preview from "./Preview";
import { useSelector } from "react-redux";
import Reset from "../Reset";
import { Helmet } from "react-helmet";


const Main_Builder = () => {

  const [resetActive,setisResetActive] = useState(false)

  const [isPreviewActive,setIsPreviewActive] = useState(false)

  const handlePreview = () => {
    setIsPreviewActive(true)
  }

 
  return (
    <>
     <Helmet>
            <meta charSet="utf-8" />
            <title>Resume Builder . QuickRezume.com</title>
            <link rel="canonical" href="/resume-builder/" />
         </Helmet>
      
        <div className=" sm:w-full lg:w-[100%] min-h-[100vh] flex flex-col  ">
        {/* <div className=" sm:w-full lg:w-[calc(100%-200px)] min-h-[100vh] flex flex-col  "> */}
 
          {/**Main Content Part**/}
          <div className="w-full min-h-[100vh] flex flex-row ">
            
            {/**Cv Content Form**/}
            <FormBuilder />
            {/**Cv Preview**/}
            <Preview setisResetActive={setisResetActive} isPreviewActive={isPreviewActive}  setIsPreviewActive={setIsPreviewActive}/>
            <Reset resetActive={resetActive} setisResetActive={setisResetActive}/>
            <button onClick={handlePreview} className=" sm:visible lg:hidden w-[100px] bg-blue-400 text-white rounded-md px-6 py-2 fixed bottom-5 right-5" >Preview</button>

          </div>
        </div>
    
    </>
  );
};

export default Main_Builder;
