import React from 'react'
import Header from '../../../Shared/Header'
import Footer from '../../../Shared/Footer'
import { Helmet } from 'react-helmet'


const PrivacyPolicy = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy-Policy</title>
                <link rel="canonical" href="/privacy-policies" />
            </Helmet>
            <Header />
            <div className='w-full mx-auto max-w-[1280px] px-12 '>
                <div className='w-[full] h-[32vh] bg-blue-400 mb-[48px] flex flex-col justify-center items-center '>
                    <h2 className='text-[24px] text-white font-poppins font-semibold'>Privacy Policy</h2>
                    <p className='font-inter text-[16px] text-white'> Last updated: August 14, 2024</p>

                </div>
                <div className='w-full flex flex-col gap-4 py-[48px]'>
                    <p className='text-[16px] font-inter'>This is the privacy policy of Quick Rezume. Your privacy is very important to us, so we have developed this beneficial document as a means of showing you how we collect, use, and protect your information when you use our Website and Services.
                    </p>
                    <h3 className='text-[18px] font-inter font-semibold'>1. Information We Collect</h3>
                    <p className='text-[16px] font-inter'>We may collect a few types of information, such as various forms of followings.
                        Personal Information: Your name, email address, and any other information you consider
                    </p>
                    <ul className='text-[16px] font-inter'>
                        <li>
                            <p><strong>Personal Information: </strong> Your name, email address, and any other information you consider appropriate to include in your Account or to our provision of the services.
                            </p>
                        </li>
                        <li>
                            <p><strong>Usage data: </strong>It can include any information connected with how a user is using our website: IP address of a user, browser type, pages a user visits, time spent on our website, etc.
                            </p>
                        </li>
                    </ul>
                    <h3 className='text-[18px] font-inter font-semibold'>2. How We Use Your Information</h3>
                    <p className='text-[16px] font-inter'>
                        The information we collect may be used for the following purposes, including:
                    </p>
                    <ul className='text-[16px] font-inter'>
                        <li>
                            <p><strong>To Provide Services:</strong> To create and manage your account, make resumes, and provide support.

                            </p>
                        </li>
                        <li>
                            <p><strong>To Improve Our Services:</strong>This is to analyze user behavior and enhance the functionality of our website.

                            </p>
                        </li>
                        <li>
                            <p><strong>Communication:</strong>To forward updates, newsletters, and information regarding the services to you.</p>
                        </li>
                    </ul>

                    <h3 className='text-[18px] font-inter font-semibold'>3. How We Protect Your Information</h3>
                    <p className='text-[16px] font-inter'>
                        We undertake to keep all information provided to us secure. Proper technical and organizational measures to protect information against unauthorized access, change, exposure, or destruction shall be continually taken.
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'>4. Sharing Your Information</h3>
                    <p className='text-[16px] font-inter'>
                        We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or necessary to provide our services.
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'>5. Third-Party</h3>
                    <p className='text-[16px] font-inter'>
                        Other sites that we may link to in our own resources, pages, or documents are not regularly monitored or controlled for their content or privacy policies. We recommend that you personally take the necessary steps to read the privacy policies that are contained on every third-party website you visit.
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'>6. Your Rights</h3>
                    <p className='text-[16px] font-inter'>
                        View the personal data we hold about you. <br />
                        Request for any incomplete or incorrect information to be rectified. <br />
                        Request that your personal data be erased, making it subject to legal and contractual obligations. <br />
                        Unsubscribe from marketing messages. <br />
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'>7. Changes to This Privacy Policy</h3>
                    <p className='text-[16px] font-inter'>
                    Update We may update our Privacy Policy from time to time. All the updates will be posted in this page and an update in the effective date will be indicated. We encourage you to periodically visit this Policy
                    </p>

                    <h3 className='text-[18px] font-inter font-semibold'>8. Contact Us</h3>
                    <p className='text-[16px] font-inter'>
                    If you have any questions or concerns about this Privacy Policy, please contact us at quickrezume@gmail.com.
                    </p>


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
