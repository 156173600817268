import React from 'react'
// import styles from '../style'
import { Link } from 'react-router-dom'
import {  facebook, instagram, linkedin, youtube } from '../assets'
import LinkFaq from './Link'

const Footer = () => {
  return (
    <div className='bg-[#0f141e] w-full pt-12  flex-col gap-5 items-center   mx-auto bottom-0 py-10 flex justify-center ' >
      <div className='w-full max-w-[1280px] flex px-10 gap-10 lg:gap-0 flex-col justify-center lg:flex-row '>
        <div className=' lg:w-[25%] flex justify-center lg:justify-start relative lg:-top-16 right-12 '>
          <div className='w-[280px] ' >
            <img className='' src="/quickrezumewhite.png" alt="" />
          </div>
        </div>
        <div className='w-full md:w-[80%] px-[30%] md:px-0  lg:w-[60%] flex flex-col gap-10 lg:gap-0 md:flex-row ' >

          {/* Pages */}

          <div className='flex flex-col md:items-start w-full lg:w-[30%] '>
            <h3 className='text-[18px] font-medium font-Inter text-white uppercase tracking-widest'>Pages</h3>
            <div className='flex flex-col md:items-start gap-[15px] font-Inter mt-6'>
              <Link to={'/about'} className='text-white capitalize text-[16px]'>about us</Link>
              <Link to='/blogs' className='text-white capitalize text-[16px]'>blogs</Link>
              <Link to={'/privacy-policies'} className='text-white capitalize text-[16px]'>privacy policy</Link>
              <Link to={'/disclaimer'} className='text-white capitalize text-[16px]'>Disclaimer</Link>
              <Link to={'/terms&conditions'} className='text-white capitalize text-[16px]'>Terms & Conditions</Link>
            </div>
          </div>

          {/* Services */}

          <div className='flex flex-col md:items-start w-full lg:w-[40%] '>
            <h3 className='text-[18px] font-medium font-Inter text-white uppercase tracking-widest'>Services</h3>
            <div className='flex flex-col md:items-start gap-[15px] font-Inter mt-6'>
              <Link to={'/resume-templates'} className='text-white capitalize text-[16px]'>Resume Builder</Link>
              <Link to={'/resume-templates'} className='text-white capitalize text-[16px]'>Resume Templates</Link>
              
            </div>
          </div>

          {/* Help */}

          <div className='flex flex-col md:items-start w-full md:w-[40%] lg:w-[20%]'>
            <h3 className='text-[18px] font-medium font-Inter text-white uppercase tracking-widest'>Help</h3>
            <div className='flex flex-col md:items-start gap-[15px] font-Inter mt-6 text-white capitalize'>
              <Link to={"/contact"} className='text-white capitalize'>contact us</Link>
              <LinkFaq />
            </div>
          </div>

        </div>
        <div className='w-full lg:w-[25%] flex flex-col items-center gap-[15px]'>
          <h3 className='text-[18px] font-medium font-Inter text-white uppercase tracking-widest'>Connect with us</h3>
          <div className='flex flex-row sm:justify-center md:justify-end gap-6'>
            <a href="https://www.facebook.com/quickrezume"><img className='' src={facebook} alt="" /></a>
            <a href="https://www.linkedin.com/company/quick-rezume"><img src={linkedin} alt="" /></a>
            <img className='' src={youtube} alt="" />
            <img className='' src={instagram} alt="" />
          </div>
        </div>
      </div>
      <span className='bg-[#1E293B] w-full h-[0.1px] mt-[40px] ' ></span>
      <span className=' sm:text-[11px] md:text-[14px] font-Inter text-[#CBD5E1] max-w-[1280px] mt-[40px] mb-[20px]' >© Copyright 2024, All Rights Reserved by Quick Rezume</span>

    </div>
    
  )
}

export default Footer
