import React from 'react'
import { fedex, ebay, intel, netflix, samsung, amazon } from '../../assets'
import Slider from 'react-infinite-logo-slider'

const Brand = () => {

    const brands = [
        {
            img: fedex,
            alt: "fedex"
        },
        {
            img: samsung,
            alt: "samsung"
        },
        {
            img: netflix,
            alt: "netflix"
        },
        {
            img: intel,
            alt: "intel"
        },
        {
            img: amazon,
            alt: "amazon"
        },
        // {
        //     img: visa,
        //     alt: "visa"
        // },
        // {
        //     img: imdb,
        //     alt: "imdb"
        // },
        {
            img: ebay,
            alt: "ebay"
        },
    ]

    return (
        <Slider
            duration={40}
            pauseOnHover={true}
            blurBorders={false}
            blurBoderColor={'#fff'}
            className='w-full bg-white h-[120px] overflow-hidden    '
        >
            {brands.map((items)=>(
                <Slider.Slide> 
                <img className='w-20 aspect-[3/2] object-contain'  src={items.img} alt={items.alt} />
            </Slider.Slide>
            ))}
        </Slider>
    )
}

export default Brand
