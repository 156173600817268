import React from 'react'
import DataExperience from './dataExperience'

const Experience = () => {
  return (
    <div className='w-full h-auto flex flex-col gap-4' >
      <div className='w-full h-auto flex flex-col gap-2' >
        <h2 className='text-darkblue sm:text-[18px] xl:text-[22px] font-poppins font-[500] ' >Experience</h2>
        <p className='text-darkblue text-[14px] font-khula ' >Start with your most recent experience and work backward.</p>
      </div>
      <DataExperience/>
    </div>
  )
}

export default Experience