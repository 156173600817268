import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLanguage, removeLanguage } from '../../../../../../../Redux/languages'
import Spinner from '../../../../../../../Shared/spinner'



const AddLanguages = ({ index, item }) => {

    const dispatch = useDispatch()


    const level = {
        basic: {
            dark: "#fe7d8b",
            light: "#ffeaec"
        },
        conversational: {
            dark: "#f68559",
            light: "#feebe3"
        },
        proficient: {
            dark: "#ec930c",
            light: "#fff2cc"
        },
        experienced: {
            dark: "#48ba75",
            light: "#e7f4ed"
        },
        fluent: {
            dark: "#9ba1fb",
            light: "#f1f2ff"
        }
    }

    const [isActive, setIsActive] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const [language, setLanguage] = useState(item || {
        language: "",
        languageLevel: "basic",
        tabPosition: 0
    })

    const handleAccordion = () => {
        setIsActive((prev) => !prev);
    };

    const handleInput = (e) => {
        const { value } = e.target;
        setLanguage({
            ...language,
            language: value
        })
    }

    const handleLevel = (level, position) => {
        setLanguage({
            ...language,
            languageLevel: level,
            tabPosition: position
        })
    }

    const handleSave = () => {
        setLoading(true); // Set loading to true immediately

        const timeout = setTimeout(() => {
            const payload = {
                position: index,
                data: language
            }

            dispatch(updateLanguage(payload))
            setIsActive((prev) => !prev);
            setLoading(false); // Set loading back to false after the timeout
        }, 2000); // 2 seconds

        return () => clearTimeout(timeout);
    };

    const delLanguage = () => {
        dispatch(removeLanguage(index))
    }

    const disabled = !language.language


    return (
        <div>
            <div className='w-full h-auto flex flex-row gap-2  group relative' >
                <div className={`w-[97%] min-h-[60px] max-h-auto flex flex-col gap-2 border-[1px] shadow-sm rounded-sm`} >
                    {/**Header**/}
                    <div onClick={handleAccordion} className="w-full h-[60px] flex flex-row items-center justify-between px-4 cursor-pointer">
                        <div className='flex flex-col gap-1' >
                            <h2 className='text-[14px] font-sans font-[600] capitalize group-hover:text-[#1A91F0] transition-colors' >{language.language ? language.language : "(Not Specified)"}</h2>
                            <span className="text-[12px] font-sans text-gray-500 capitalize ">
                                {language.languageLevel}
                            </span>
                        </div>
                        <span
                            className={`material-symbols-outlined cursor-pointer group-hover:text-[#1A91F0] transition-colors ${isActive ? " rotate-180 " : ""
                                } transition-all  `}
                        >
                            expand_more
                        </span>
                    </div>
                    {/**Body**/}
                    <div
                        className={`w-full ${isActive ? "  " : "h-0 opacity-0 pointer-events-none "
                            }   transition-all    `}
                    >
                        <div className="flex h-auto sm:flex-col md:flex-row sm:gap-4 lg:gap-6  pt-2 pb-3 px-4 ">
                            {/***Skills**/}
                            <div className='sm:w-full md:w-1/2 flex flex-col gap-1' >
                                <h2 className='text-[14px] font-sans text-gray-500 h-[21px] ' >Language</h2>
                                <input onChange={handleInput} value={language.language} type='text' className=' px-4 py-2 bg-[#EFF2F9] h-[40px] rounded-sm outline-none focus:border-[2px] border-b-[#1A91F0]' />
                            </div>
                            {/**Level**/}
                            <div className='sm:w-full md:w-1/2 flex flex-col gap-1' >
                                <div className='h-[21px]' >
                                    <span className='text-[14px] font-sans text-gray-500  ' >Level - </span>
                                    <span className='text-[14px] font-sans capitalize ' > {language.languageLevel}</span>
                                </div>

                                <div style={{ background: level[language.languageLevel]?.light }} className={`h-[40px] rounded-md flex flex-row relative`} >
                                    <span style={{ position: "absolute", left: `${language.tabPosition}%`, top: "0", backgroundColor: level[language.languageLevel]?.dark }} className={` w-[20%] transition-all h-full `} ></span>
                                    <span onClick={() => handleLevel("basic", 0)} className={`w-[20%] h-full hover:bg-[${level.basic.dark}]  rounded-md cursor-pointer flex items-center`} ></span>
                                    <span onClick={() => handleLevel("conversational", 20)} className={`w-[20%] h-full  hover:bg-[${level.conversational.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                                    <span onClick={() => handleLevel("proficient", 40)} className={`w-[20%] h-full  hover:bg-[${level.proficient.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                                    <span onClick={() => handleLevel("experienced", 60)} className={`w-[20%] h-full hover:bg-[${level.experienced.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                                    <span onClick={() => handleLevel("fluent", 80)} className={`w-[20%] h-full hover:bg-[${level.fluent.dark}] rounded-md cursor-pointer flex items-center `} >|</span>
                                </div>
                            </div>

                        </div>
                        <div className=" flex justify-end item-center mb-2 mx-4  mt-2  ">
                            <button
                                disabled={disabled || isLoading}
                                onClick={handleSave}
                                className="bg-[#0a072bfd] hover:bg-[#0a072bdb] disabled:cursor-not-allowed disabled:bg-[#0a072bdb] text-white font-roboto px-6 py-1 rounded-sm  "
                            >
                                {isLoading ? <Spinner /> : "Save"}
                            </button>
                        </div>

                    </div>
                </div>
                <span
                    onClick={delLanguage}
                    className="material-symbols-outlined text-gray-200 hidden group-hover:block hover:text-[#1A91F0] cursor-pointer mt-[20px] absolute right-[-20px]  "
                >
                    delete
                </span>
            </div>
        </div>
    )
}

export default AddLanguages
