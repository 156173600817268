import React,{useEffect} from "react";
import Main_Builder from "./Main-Builder";
import Google_Ads from "./Google-Ads";
import { useLocation,useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import Reset from "./Reset";

const ResumeBuilder = ({}) => {

  const { state } = useLocation();
  const navigate = useNavigate()

  const currentTemplate = useSelector(
    (state) => state.template.currentTemplate
  );


  useEffect(() => {
    if (currentTemplate) {
      return;
    } else {
      window.location.replace("/resume-templates");
    }
  }, []);


  return (
    <>
      {
        currentTemplate && 
        <div className="w-full h-[100vh] flex sm:flex-col lg:flex-row  ">
        {/**Main Builder Part**/}
        <Main_Builder/>
        {/**Google Ads Part**/}
        {/* <Google_Ads/> */}
      </div>
      }
    </>
  );
};

export default ResumeBuilder;
