import React from 'react'
import { Helmet } from 'react-helmet'
import thumbnail from "./thumbnail.jpg"

const Blog1 = () => {


    return (
        <>
            <div className='h-auto flex items-start flex-col gap-8 mt-[40px]  py-3 ' >

                <Helmet>
                    <meta charSet="utf-8" />
                    <title> How to write an Effective resume</title>
                    <meta name="Your resume is a marketing tool to outline your experience, skills, and achievements. Tailor-make it for all the jobs you apply for by pointing out the most relevant information. A good resume must be very clear, concise, and well-organized." />
                    <link rel="canonical" href="blog/how-to-write-an-effective-resume" />
                </Helmet>

                <h2 className='text-[35px] font-poppins text-black font-semibold' >How to Write an Effective Resume</h2>
                <div style={{ backgroundImage: `url(${thumbnail})` }} className='w-full h-[300px] bg-black bg-cover bg-center' ></div>
                <div className=''>
                    <div className='w-full px-2 py-2 flex flex-col gap-10 mt-4'>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>1. Introduction</h3>
                            <p>Your resume is a marketing tool to outline your experience, skills, and achievements. Tailor-make it for all the jobs you apply for by pointing out the most relevant information. A good resume must be clean, simple and well-organized</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>2. Contact Information</h3>
                            <p>
                                This must be the heading of your resume, and it is required to contain the following:
                            </p>
                            <p>. Full Name </p>
                            <p>. Phone Number</p>
                            <p>. Email Address</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>3. Objective or Summary</h3>
                            <p>The summary or objective section consists of a brief statement of your career goals and what an applicant can contribute to the table. This should be tailored for every job application.
                            </p>
                            <p>. <span className=' font-semibold'>Example Objective:</span>"A meticulous software engineer with 5 years of experience in scalable web application development seeks to apply my full-stack development skills at XYZ Corporation."

                                "</p>
                            <p>. <span className=' font-semibold'>Summary Example:</span>" Senior marketing professional with over 10 years of work experience in digital marketing, content creation, and brand strategy. Prove record of performance in driving growth and engaging Fortune 500 companies."</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>4. Professional Experience</h3>
                            <p>Type out your work experience in reverse order; that is, start with your most recent job</p>
                            <p>. Job Title</p>
                            <p>. Company Name</p>
                            <p>. Location</p>
                            <p>. Dates Of Employment</p>
                            <p>. Responsibilities and Achievements
                            </p>
                            <p>Using bullets, outline the job responsibilities and achievements, describing measurable results whenever possible.</p>

                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>5. Education</h3>
                            <p>It should include your educational background, starting with the highest degree attained. It includes:</p>
                            <p>. Degree</p>
                            <p>. Institution</p>
                            <p>. Location</p>
                            <p>. Graduation Dates</p>
                            <p>. Relevant Coursework or Honors Summary
                            </p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>6. Skills</h3>
                            <p>This section should outline your major abilities & skills. If possible, break them down into categories. </p>
                            <span className=' flex flex-col'>
                                <h6 className=' font-semibold'>Technical Skills:</h6>
                                <div className=''>
                                    <p>. Programming Languages: Java, Python, C++</p>
                                    <p>. Web Technologies: HTML, CSS, JavaScript, React</p>
                                    <p>. Tools: Git, Docker, Jenkins</p>
                                </div>
                            </span>
                            <span className=' flex flex-col'>
                                <h6 className=' font-semibold'>Soft Skills:</h6>
                                <div className=''>
                                    <p>. Communication</p>
                                    <p>. Problem-Solving</p>
                                    <p>. Leadership</p>
                                </div>
                            </span>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>7. References</h3>
                            <p>You can either include references, or you can mention that references are available upon request. If you include them:</p>
                            <p>. Reference Name</p>
                            <p>. Title</p>
                            <p>. Company</p>
                            <p>. Contact Information</p>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Blog1