import React, { useEffect, useState } from 'react'
import styles from '../../../style'
import "./Steps.css"
import { create } from '../../../assets';
import templates from './templates.svg'


const Steps = () => {

  const [active, setActive] = useState('step1')

  useEffect(()=>{
    const timer = setTimeout(() => {
      if(active == "step1"){
        setActive('step2'); 
      }
      else if (active == "step2"){
        setActive('step3')
      }
      else{
        setActive('step1')
      }
    }, 3000);

    return () => clearTimeout(timer);
  })

  return (

    <div className='w-full h-auto py-10 '>
      <div className='flex flex-col-reverse md:flex-row md:px-20 items-center justify-center gap-10 md:gap-20 lg:gap-48 '>
        <div className='w-[350px] md:w-[450px] md:h-[450px] overflow-hidden'>
          <img src={templates} alt="" />
        </div>
        <div className='w-[330px] flex flex-col gap-6'>
          <p className='uppercase text-[12px] font-semibold font-poppins text-[#2A84FF]'>How it works</p>
          <span className='text-[40px] leading-10 font-poppins font-semibold '>
            <h2>3 Steps.</h2>
            <h2>5 Minutes.</h2>
          </span>
          <p className=' font-poppins text-gray-500'>Getting that dream job can seem like a difficult task. We're here to change that. Give yourself a real advantage with the free online resume maker; create by experts & trusted by thousands of professionals </p>
          <a href="https://youtu.be/wwma6VkknpU">
          <button className='bg-[#2A84FF] text-white font-poppins w-[150px] h-[40px] rounded-full'>Watch A Video</button>
          </a>
        </div>
      </div>
      <div className='w-full h-auto mt-14 px-4  lg:px-10 flex flex-col md:flex-row gap-5 justify-center items-center md:flex-wrap '>

        <div onClick={() => setActive('step1')} className={` cursor-pointer w-[350px] h-[400px] rounded-3xl overflow-hidden px-10 py-8 flex flex-col gap-16 ${active === "step1" ? 'bg-[#2A84FF]' : "bg-[#F6F7F9]"}`}>
          <div className={`w-[90px] h-[90px] flex justify-center items-center  font-poppins rounded-3xl font-extrabold text-[25px] ${active === 'step1' ? 'bg-[white] text-[#2A84FF] ' : 'text-white bg-black '}`}>1.</div>
          <div className='flex flex-col gap-4'>
            <h1 className={` font-poppins text-[25px] font-semibold  ${active === 'step1' ? 'text-white' : 'text-black'}`}>Choose a Free <br /> Resume Template</h1>
            <p className={`font-poppins text-[14px] ${active === 'step1' ? 'text-gray-200' : "text-gray-600"}`}>Every resume template is expertly designed and follows the precise "resume rules" that employers seek.</p>
          </div>
        </div>
        
        <div onClick={() => setActive('step2')} className={` cursor-pointer w-[350px] h-[400px] rounded-3xl overflow-hidden px-10 py-8 flex flex-col gap-16 ${active === "step2" ? 'bg-[#2A84FF]' : "bg-[#F6F7F9]"}`}>
          <div className={`w-[90px] h-[90px] flex justify-center items-center  font-poppins rounded-3xl font-extrabold text-[25px] ${active === 'step2' ? 'bg-[white] text-[#2A84FF] ' : 'text-white bg-black '}`}>2.</div>
          <div className='flex flex-col gap-4'>
            <h1 className={` font-poppins text-[25px] font-semibold  ${active === 'step2' ? 'text-white' : 'text-black'}`}>Add your  <br /> information</h1>
            <p className={`font-poppins text-[14px] ${active === 'step2' ? 'text-gray-200' : "text-gray-600"}`}>This step transforms raw information into a polished, visually appealing resume that captures attention and emphasizes professional prowess.</p>
          </div>
        </div>

        <div onClick={() => setActive('step3')} className={` w-[350px] h-[400px] cursor-pointer rounded-3xl overflow-hidden px-10 py-8 flex flex-col gap-16 ${active === "step3" ? 'bg-[#2A84FF]' : "bg-[#F6F7F9]"}`}>
          <div className={`w-[90px] h-[90px] flex justify-center items-center font-poppins rounded-3xl font-extrabold text-[25px] ${active === 'step3' ? 'bg-[white] text-[#2A84FF] ' : 'text-white bg-black'}`}>3.</div>
          <div className='flex flex-col gap-4'>
            <h1 className={` font-poppins text-[25px] font-semibold  ${active === 'step3' ? 'text-white' : 'text-black'}`}>Export Your   <br /> Resume</h1>
            <p className={`font-poppins text-[14px] ${active === 'step3' ? 'text-gray-200' : "text-gray-600"}`}>Download your new resume in the available formats. Now the resume is Yours!</p>
          </div>
        </div>

      </div>

    {/* <div className='h-[450px] mt-12 flex justify-center'>
      <div></div>
      <img className='w-[800px]' src="/media/mockup.png" alt="" />
    </div> */}


    </div>
  )
}

export default Steps