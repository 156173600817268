import React from 'react'
import AddHobbies from './addHobbies'
import Hobby from './hobby'
import { useSelector,  useDispatch } from 'react-redux'
import { setTabCompletion,selectedTab } from "../../../../../../Redux/builder";
import { useNavigate } from 'react-router-dom';


const Hobbies = () => {

  const hobbies = useSelector((state)=>state.myhobbies.hobbies)
  const currentTemplate = useSelector((state)=> state.template.currentTemplate)

  const dispatch = useDispatch();
  const Navigate = useNavigate()

  const handleNext = () => {
    dispatch(setTabCompletion({ tabName: "Hobbies", completed: true }));
    Navigate(`/resume-builder/${currentTemplate.name}/download`)
    dispatch(selectedTab("Download"))
  }

  const handleBack = () =>{
    dispatch(selectedTab('Skills'));
    Navigate(`/resume-builder/${currentTemplate.name}/skills`)
  }

  console.log(hobbies)

  return (
    <div className="w-full h-auto flex flex-col gap-4">
      <div className="w-full h-auto flex flex-col gap-2">
        <h2 className="text-darkblue sm:text-[18px] xl:text-[22px] font-poppins font-[500] ">
          Hobbies
        </h2>
        <p className="text-darkblue text-[14px] font-khula ">
        Activities you  done regularly in one's leisure time for pleasure.
        </p>
      </div>
      <AddHobbies/>
      <div className='w-full flex flex-wrap gap-2' >

      {
        hobbies && hobbies.length > 0 && hobbies.map((item,index)=>{
          return(
                  <Hobby item={item} index={index} key={index} />
          )
        })
      }

      </div>
      <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-4 mt-[30px]  ">
        <button onClick={handleBack} className="sm:w-full lg:w-1/2  h-[40px] text-black font-alata  flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm  hover:bg-[#0097e6] hover:text-white     ">
          {" "}
          Back{" "}
        </button>
        <button
          onClick={handleNext}
          className={`sm:w-full lg:w-1/2  h-[40px] text-white font-alata cursor-pointer   flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm bg-[#0097e6]  hover:bg-[#0a072bfd]    `}
        >
          {" "}
          Next{" "}
        </button>
      </div>
    </div>
  )
}

export default Hobbies