import React from 'react'
import { Helmet } from 'react-helmet'
import thumbnail from './blog2.png'

const Blog2 = () => {


    return (
        <>
            <div className='h-auto flex items-start flex-col gap-8 mt-[40px]  py-3 ' >

                <Helmet>
                    <meta charSet="utf-8" />
                    <title> Common Resume Mistakes to Avoid</title>
                    <meta name="At present, making an extraordinarily great resume is one of the most important things to get your dream job. However, there exist too many common mistakes that too many job seekers make unnecessarily. Here are some of those typical mistakes on the resumes, followed by their proposed correction strategies" />
                    <link rel="canonical" href="blog/how-to-write-an-effective-resume" />
                </Helmet>

                <h2 className='text-[35px] font-poppins text-black font-semibold' >Common Resume Mistakes to Avoid</h2>
                <div style={{ backgroundImage: `url(${thumbnail})` }} className='w-full h-[300px] bg-black bg-cover bg-center' ></div>
                <div className=''>
                    <div className='w-full px-2 py-2 flex flex-col gap-10 mt-4'>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <p>At present, making an extraordinarily great resume is one of the most important things to get your dream job. However, there exist too many common mistakes that too many job seekers make unnecessarily. Here are some of those typical mistakes on the resumes, followed by their proposed correction strategies:</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>1. Typos and Grammatical Errors</h3>
                            <p>. <span className='font-semibold'>Mistake : </span>  Misspelling, faulty grammar, and punctuation mistakes have burrowed their way as the top classes of resume mistakes.</p>
                            <p>. <span className='font-semibold'>Solution:</span> Proofread your resume several times, and make sure to use tools like Grammarly. Consider having a friend or professional review it as well. </p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>2. Lack of Customization</h3>
                            <p>. <span className='font-semibold'>Mistake : </span> Sending out the exact same resume for every job application. </p>
                            <p>. <span className='font-semibold'>Solution:</span> Tailor your resume for each position. Hirers look at a lot of resumes, so make sure to put relevant skills and experiences that match the job description at the top.</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>3. Too Long or Too Short</h3>
                            <p>. <span className='font-semibold'>Mistake : </span> Resumes that are either long or too short </p>
                            <p>. <span className='font-semibold'>Solution:</span> If you have less than five years experince then your resume should not be more than one page. Use simple and straightforward language in describing your credentials.</p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>4. Unprofessional Email Address</h3>
                            <p>. <span className='font-semibold'>Mistake : </span> Using an unprofessional or obsolete email address.</p>
                            <p>. <span className='font-semibold'>Solution:</span> Create a professional email address consisting of the mention of your name, e.g. firstname.lastname@gmail.com </p>
                        </div>
                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>5. Including Irrelevant Information</h3>
                            <p>. <span className='font-semibold'>Mistake : </span> Mentioning jobs not relevant to the applied position, hobby, or other personal information. </p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>6. No Clear Career Objective or Summary</h3>
                            <p>. <span className='font-semibold'>Mistake : </span> You may not begin your resume with a clear objective or summary statement. </p>
                            <p>. <span className='font-semibold'>Solution:</span> To avoid this mistake, compost a very short summary or objective and place it at the top of the resume to identify where you are in your career and what you can offer.  </p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>7. Poor Formatting</h3>
                            <p>. <span className='font-semibold'>Mistake : </span>  Using different fonts, margins, or style formats. </p>
                            <p>. <span className='font-semibold'>Solution:</span> It should be in clear format with consistent fonts and consistent spacing. Be sure that your resume is easy to read and easy to flow through.  </p>
                        </div>

                        <div className=' font-poppins flex flex-col gap-2'>
                            <h3 className='text-[20px] '>8. Ignoring Keywords</h3>
                            <p>. <span className='font-semibold'>Mistake : </span> Not including keywords in a job description </p>
                            <p>. <span className='font-semibold'>Solution:</span>Use relevant keywords and corresponding terms that are seen in the job posting to successfully pass through the applicant-tracking system, and your exposure to the hiring manager or employer will be maximized.  </p>
                        </div>

                        <div className='font-poppins' >
                            <p>These are typical errors in a resume that may be avoided just because you're a little careful. Staying clear of these common mistakes will help give your resume that extra oomph to stand in front of potential employers. Keep in mind, your resume is the first impression, so make it count!</p>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default Blog2