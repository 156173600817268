import React, { useState } from 'react'
import "./index.css"
import TemplateEditor from './template'
import EditPreview from './preview'
import { AllTemplates } from '../../Templates/templates'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Spinner from '../../../Shared/spinner'
import { useNavigate, Link, Navigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'


const Editor = () => {

    const state = useSelector((state) => state);
    const currentTemplate = useSelector((state) => state.template.currentTemplate);
    const [isLoading, setLoading] = useState(false)  
    
    const SERVERURL = process.env.REACT_APP_BASE_URL;



    const downloadPdf = async () => {

        if (!currentTemplate) {
            return
        }

        setLoading(true)

        const { allSkills, education, experience, myhobbies, profile, mylanguages, template } = state;


        console.log("CLICKED")

        const base64String = localStorage.getItem('profilePic');
        if (base64String) {
            // Convert base64 string to Blob
            const byteCharacters = atob(base64String.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });

            // Create a File object from Blob
            const imageFile = new File([blob], 'image.png', { type: 'image/png' });
            console.log(imageFile);

            const formData = new FormData();
            formData.append('image', imageFile);

            // Send POST request to server
            const res = await axios.post(`${SERVERURL}/upload/image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res)
            if (res.data.fileName) {
                let payload = {
                    allSkills, education, experience, myhobbies,
                    profile,
                    profileImage: res.data.fileName,
                    templateId: currentTemplate?.name,

                };
                axios({
                    method: 'post',
                    url: `${SERVERURL}/upload/image`,
                    data: payload, // you are sending body instead
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then(async res => {
                    console.log(res);
                    const fileUrl = `${SERVERURL}${res.data.file}`;

                    const response = await axios.get(fileUrl, {
                        responseType: 'blob'
                    });

                    setLoading(false)


                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'resume.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
            }
        }

    }
    
    const navigate = useNavigate()

    const handleBack = () =>{
    window.location.replace(`/resume-builder/${currentTemplate.name}/profile`)
        navigate(`/resume-builder/${currentTemplate}/profile`)
    }

    const disabled = isLoading

    const handleToast = ()=>{
        // setTimeout(()=>{
          toast.success(`Templated Selected`, {
            position: "top-right",
           },5000)
    //    });
      }

    return (
        <div className='w-full h-[100%] flex flex-col   '  >
             <Helmet>
            <meta charSet="utf-8" />
            <title>Editor . QuickRezume.com</title>
            <link rel="canonical" href="/resumes/edit" />
         </Helmet>
          <ToastContainer/>

            <div  className='w-full h-[70px] bg-[#0f141e]  flex flex-row justify-between px-6 ' >
                <div onClick={handleBack}  className='px-1 h-full flex flex-row items-center gap-1 text-center  cursor-pointer' >
                    <img src='/media/back-arrow.svg' className='h-[20px]' />
                    <h2 className='text-white text-[16px] font-poppins' >Back to editor</h2>
                </div>
                <div className='w-1/2 h-full flex flex-row items-center gap-1 justify-end ' >

                    <button onClick={downloadPdf}
                        className='bg-[#1a91f0] text-white font-alata w-[150px]  px-4 h-[40px] rounded-md transition-all active:scale-95  ' >
                            {isLoading ? <Spinner/>  : "Download PDF"}
                    </button>

                </div>

            </div>

            <div className='w-full h-[calc(100vh-70px)] bg-[#495163] flex flex-row  ' >
                {/**** TEMPLATES ****/}
                <div className='w-full lg:w-[400px] h-[100%] border-r-[1px] border-gray-400 px-1 py-1 '>

                    <div className='w-full h-full flex justify-evenly flex-wrap  gap-4 px-4 py-4 overflow-y-auto scroll-container ' >
                        {
                            AllTemplates?.map((item, index) => {
                                return (
                                    <TemplateEditor item={item} />
                                )
                            })
                        }
                    </div>

                </div>
                {/***PREVIEW */}
                <EditPreview />
            </div>
        </div>
    )
}

export default Editor