import { createSlice } from "@reduxjs/toolkit";

const Tabs = JSON.parse(localStorage.getItem("Steppers"))


const initialState = {
  selectedTab: "Profile",
  StepperTabs: Tabs ? Tabs : [
    {
      Stepper: "Profile",
      status: false,
      text: "1",
      route: "profile",
    },
    {
      Stepper: "Education",
      status: false,
      text: "2",
      route: "education",
    },
    {
      Stepper: "Experience",
      status: false,
      text: "3",
      route: "experience",
    },
    {
      Stepper: "Skills",
      status: false,
      text: "4",
      route: "skills",
    },
    {
      Stepper: "Hobbies",
      status: false,
      text: "5",
      route: "hobbies",
    },
    {
      Stepper: "Download",
      status: false,
      text: "6",
      route: "download",
    },
  ],
};

export const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    setTabCompletion: (state, action) => {
      const { tabName, completed } = action.payload;

      const updatedStepperTabs = state.StepperTabs.map((item) => {
        if (item.Stepper === tabName) {
          return { ...item, status: completed };
        } else {
          return item;
        }
      });

      state.StepperTabs = updatedStepperTabs;
      localStorage.setItem("Steppers", JSON.stringify(updatedStepperTabs));
    },
    selectedTab: (state, action) => {
      state.selectedTab = action.payload;
      localStorage.setItem("selectedTab", action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleSelectedTab, setTabCompletion, selectedTab } =
  builderSlice.actions;

export default builderSlice.reducer;
