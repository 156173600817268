import React, { useState, useEffect, lazy, Suspense } from "react";
import { useSelector } from "react-redux";


const EditPreview = () => {

    const currenttemplate = useSelector(
        (state) => state.template.currentTemplate
      );
    
      const Component = lazy(() =>
        import(`../../Templates${currenttemplate.path}`)
      );
      
      useEffect(()=>{
        if(!currenttemplate){
            window.history.back() 
        }
      },[])

      const personalData = useSelector((state) => state.profile.personalData);
      const educationList = useSelector((state) => state.education.educationList);
      const skills = useSelector((state) => state.allSkills.skills);
      const experience = useSelector((state) => state.experience.experienceList);
      const hobbies = useSelector((state) => state.myhobbies.hobbies);
      const language = useSelector((state)=> state.mylanguages.languages)

  return (
    <div className='w-[calc(100%-400px)] h-[100%] overflow-y-auto scroll-container hidden lg:block   ' >
        <div id="downloadedTemplate" className=' lg:w-[560px] lg:min-h-[700px] max-h-auto 2xl:scale-110 bg-white rounded-md mt-[5%] mb-[3%] mx-auto   ' >
        <Suspense>
            <Component
              personalData={personalData}
              educationList={educationList}
              skills={skills}
              experience={experience}
              hobbies = {hobbies}
              languages = {language}
            />
          </Suspense>
          
        </div>
    </div>
  )
}

export default EditPreview