import { configureStore } from '@reduxjs/toolkit'
import builderSlice  from './Redux/builder'
import  profileSlice from './Redux/profile'
import  educationSlice  from './Redux/education'
import experienceSlice from './Redux/experience'
import skillsSlice  from './Redux/skills'
import hobbies from './Redux/hobbies'
import  templateSlice  from './Redux/template'
import languageSlice from './Redux/languages'

export const store = configureStore({
  reducer: {
    builder:builderSlice,
    profile:profileSlice,
    education:educationSlice,
    experience:experienceSlice,
    allSkills: skillsSlice,
    myhobbies:hobbies,
    mylanguages : languageSlice ,
    template:templateSlice
  }
})