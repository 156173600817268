import React, { useRef } from "react";
import { useEffect } from "react";

const Profile_Pic = ({ setPersonalData, personalData }) => {

  const imageRef = useRef()

  useEffect(() => {
    const storedImageData = localStorage.getItem("profilePic");

    if (storedImageData) {
      // Create a Blob from the stored image data
      const blob = dataURLtoBlob(storedImageData);

      // Create a Blob URL from the Blob
      const blobUrl = URL.createObjectURL(blob);

      setPersonalData((prevState) => ({  
        profilePic: blobUrl,
      }));

    } else {
      console.log("No stored image data found");
    }
  }, []);

  // Function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };


  const handleFile = (event) => {
    const file = event.target.files[0];

      // localStorage.setItem("profilePic", file)

    if (file) {
      const reader = new FileReader();


      reader.onload = (e) => {
        const imageData = e.target.result;

        localStorage.setItem("profilePic", imageData);

        const url = URL.createObjectURL(file);
        console.log("Blob URL:", url);

        setPersonalData((prevState) => ({
          ...prevState,
          profilePic: url,
        }));
      };

      reader.readAsDataURL(file);
    } else {
    }
  };

  return (
    <div onClick={()=>imageRef.current.click()} className="w-[300px] cursor-pointer flex flex-row items-center gap-4 mt-[15px] relative ">
      <div className="w-[80px] h-[80px] overflow-hidden  flex items-center justify-center hover:bg-[#eff2f9b9] bg-[#EFF2F9] relative  ">
        {personalData.profilePic ? (
          <img src={personalData.profilePic} alt="profile picture" />
        ) : (
          <img className="w-[60px] " src="/icons/svg/UserMale.svg" alt="profile picture" />
        )}
      </div>

        <span className="text-[18px] font-khula font-[400]  py-4 hover:text-blue-400  ">
          Upload Photo
        </span>
        <input
          onChange={handleFile}
          ref={imageRef}
          type="file"
          name="profilePic"
          accept="image/*"
          className=" absolute left-0 opacity-0  pointer-events-none "
        />
    </div>
  );
};

export default Profile_Pic;
