import React, { useState } from 'react'
import styles from "../../../style";
import {Link }from 'react-router-dom'
import Slider from './Slider';

const SelectTemplate = () => {


    return (
        <div id='downloadedTemplate' className="flex justify-center mx-auto  ">
            <div className={`${styles.flexCenter} flex-col gap-12 overflow-hidden w-[100%]   md:w-full h-auto ${styles.paddingY}  `}>
                <div className='text-center flex flex-col gap-7 '>
                    <h5 className='font-poppins text-[#2A84FF] font-medium'>Resume Templates</h5>
                    <h1 className='font-poppins text-[35px] font-semibold leading-10'>Make Your Resume With <br className='hidden md:block' /> Professional Templates</h1>
                    <p className='font-poppins text-[14px] text-gray-600 text-center w-full md:w-[600px]'>Every resume template is expertly designed and follows the precise "resume rules" that employers seek. With field-tested resume templates, you'll stand out and get hired more quickly.</p>
                </div>
                <div className='flex flex-col gap-5 items-center justify-center py-[48px] mx-auto bg-[#F6F7F9] w-full py-4'>

                    <Slider/>
                    <Link to={'/resume-templates'}>
                    <button className='bg-[#2A84FF] font-poppins rounded-lg w-[200px] h-[40px] text-white'>
                        See All Templates
                    </button>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default SelectTemplate
