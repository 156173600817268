import { createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";

// JSON.parse(localStorage.getItem("Languages")) ||

const initialState = {
  languages:  JSON.parse(localStorage.getItem("Languages")) || [],
};

const languageSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    addLanguage: (state, action) => {
      state.languages = [...state.languages, ""];
      // localStorage.setItem("Languages", JSON.stringify(state.languages));
    },
    removeLanguage: (state, action) => {
      const position = action.payload;
      const deleteLanguage = state.languages.filter((item, index) => {
        return position !== index;
      });

      localStorage.setItem("Languages", JSON.stringify(deleteLanguage));
      state.languages = deleteLanguage;
    },

    updateLanguage: (state, action) => {
      const { position, data } = action.payload;

      const newLanguage = produce(state, (draft) => {
        if (draft.languages && draft.languages.length > 0) {
          draft.languages[position] = data;
        }
      });

      const updatedLanguages = newLanguage.languages;

      localStorage.setItem("Languages", JSON.stringify(updatedLanguages));

      return newLanguage;
    },
  },
});

export const { addLanguage, removeLanguage, updateLanguage } =
  languageSlice.actions;

export default languageSlice.reducer;
