import React from "react";
import card_1 from '../../assets/Card_1.svg'
import card_2 from '../../assets/Card_2.svg'
import mockup from "../../assets/mockup.png"
import { useNavigate } from "react-router-dom";


const HeroSection = () => {

  const navigate = useNavigate()
 
  

  return (
    <div className="w-full mx-auto">

      <div className="w-full max-w-[1280px] mx-auto h-auto px-9 mt-10 lg:mt-0">
        <div className="w-full h-full lg:h-[300px] flex flex-col justify-center  lg:flex-row lg:items-center">
          <div className="flex flex-col font-poppins text-[32px] md:text-[40px] lg:text-[60px]  font-semibold">
            <span className="text-[16px] flex gap-1  px-2 py-2 rounded-full ">The
              <span className="text-[#2A84FF]">
                #1
              </span>
              Resume Builder</span>
            <h1 className="mt-8 md:mt-4 md:leading-[68px] ">Create a Job-Ready Resume in Minutes  </h1>
          </div>
          <div className=" flex flex-col gap-5  mt-8 ">
            <p className="font-poppins text-[18px] text-gray-500">Create your resume easily with our free <br className="hidden md:visible" /> builder and professional templates.</p>
            <button onClick={()=>navigate("/resume-templates")}  className="w-[150px] rounded-full text-white font-poppins text-[14px] h-[45px] bg-[#2A84FF]">
              Create Resume
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#F6F7F9] h-auto mt-32 flex justify-center  gap-5 px-2">
          <div className="w-[250px] h-[300px] relative bottom-4 lg:bottom-12 md:left-16 lg:left-0 overflow-hidden hidden md:block">
            <img  className="w-[140px] lg:w-[210px]"  src={card_1} alt="" />
          </div>
          <span className="w-[300px]  lg:w-[410px] flex justify-center relative bottom-12 lg:bottom-20">
            <img className="w-[400px]"  src={mockup} alt="" />
          </span>
          <div className="w-[210px] lg:h-[260px] overflow-hidden relative top-24 lg:top-32 right-10 hidden md:block">
              <img className="w-[140px] lg:w-[210px]" src={card_2} alt="" />
          </div>
      </div>

    </div>
  );
};

export default HeroSection;
