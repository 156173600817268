import React from 'react'
import Header from '../../../Shared/Header'
import Footer from '../../../Shared/Footer'
import { Helmet } from 'react-helmet'

const Disclaimer = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Disclaimer</title>
                <link rel="canonical" href="/privacy-policies" />
            </Helmet>
            <Header />
            <div className='w-full mx-auto max-w-[1280px] px-12'>
                <div className='w-[full] h-[32vh] bg-blue-400 mb-[48px] flex flex-col justify-center items-center '>
                    <h2 className='text-[24px] text-white font-poppins font-semibold'>Disclaimer</h2>

                </div>
                <div className='w-full flex flex-col gap-4 py-[48px]'>
                    <h1 className='text-[18px] font-inter font-semibold' >Disclaimer for Quick Rezume</h1>

                    <p className='text-[16px] font-inter'>Before you go on and directly using Quick Rezume, there are a few important matters that you have to know first. Here comes our friendly disclaimer:Here comes our friendly disclaimer:</p>

                    <h2 className='text-[18px] font-inter font-semibold'>1. No guarantees</h2>

                    <p className='text-[16px] font-inter'>As much as we have tried to give our best in order to come up with the most fantastic tool for constructing the resumes, Quick Rezume is produced with no warranty of the above benefits. We cannot in any way, assure you that our service will help you grab that particular job or that your resume will be perfectly compatible for each and every opportunity out there. Now you know what has to be done and we are here to support you if you need our help, but you are doing the rest! </p>

                    <h2 className='text-[18px] font-inter font-semibold'>
                        2. Accuracy of Information</h2>

                    <p className='text-[16px] font-inter'>It is your responsibility what you input into Quick Rezume. We do not confirm the correctness and the completeness of the information you do provide. Ensure that all the information that you give in your resume is authentic and current.</p>

                    <h2 className='text-[18px] font-inter font-semibold'>
                    3. Availability of the Services</h2>

                    <p className='text-[16px] font-inter'>We are trying our best to make Quick Rezume work and run; however it is hard to ensure that the application is going to work or be active all the time. You may incidentally experience some interruptions of the service now and then because of such things as maintenance or malfunctions.</p>

                    <h2 className='text-[18px] font-inter font-semibold'>
                    4. Use at Your Own Risk</h2>

                    <p className='text-[16px] font-inter'>Discretion is advised with any of the products as the disappointment may not be worth the money spent. <br />
                    Moreover, Quick Rezume does not warrant the performance or results of its usage that is provided by the client at his/ her own risk. We do our best to ensure the service is good but are not responsible for anything that may happen when you use our service: no refunds, no compensation for lost data, and no liability for any other damages.</p>

                    <h2 className='text-[18px] font-inter font-semibold'>
                    5. Third Party Links</h2>

                    <p className='text-[16px] font-inter'>Certain links provided within the Quick Rezume may or may not connect to other websites or other services, from time to time. These links are supplied for your convenience only. These websites belong to other people and we are not responsible for them and their contents. You should be cautious using them since they have their terms and policies that maybe difficult to understand.</p>

                    <h2 className='text-[18px] font-inter font-semibold'>
                    6. No Professional Advice</h2>

                    <p className='text-[16px] font-inter'>Quick Rezume does not provide career consultation. The tips and templates we give are of rather a general nature, and therefore you may find that they do not suit your particular case. However, if you feel like you have special circumstance that requires special attention then It is advisable that you seek advice from a career professional.</p>  

                    <h2 className='text-[18px] font-inter font-semibold'>
                    7. Changes to Our Service</h2>

                    <p className='text-[16px] font-inter'>Several things about Quick Rezume are dynamic, which implies that some things might be a little different now from what they used to be. The above information may be amended or withdrawn without prior notice in the event that any of the provisions stated above are amended or withdrawn, or in the event that the Hong Kong Institute of Certified Public Accountants revokes our licence.</p>  

                    <h2 className='text-[18px] font-inter font-semibold'>
                    8. Limitation of Liability</h2>

                    <p className='text-[16px] font-inter'>The service is provided to you AS IS and Quick Rezume and it’s team shall NOT be held responsible for any direct or indirect, special, incidental, consequential damages or any other damages or losses arising out of your use or inability to use the service.</p>  

                    <h2 className='text-[18px] font-inter font-semibold'>
                    9. Contact Us</h2>

                    <p className='text-[16px] font-inter'>In case of any doubts or queries regarding this disclaimer, please proceed to send an email to support@quickrezume. com. We are here to help!</p>  


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Disclaimer
