import React from 'react'
import about from './about_img.png'
import Header from "../../Shared/Header"
import Footer from '../../Shared/Footer'
import { useNavigate } from 'react-router-dom'
import rocket from './rocket.svg'
import five_star from './five_star.svg'
import { Helmet } from 'react-helmet'

const About = () => {

  const navigate = useNavigate()

  return (
    <>

<Helmet>
            <meta charSet="utf-8" />
            <title>About</title>
            <link rel="canonical" href="/about" />
         </Helmet>

      <Header />
      
      <div className={`w-full max-w-[1280px] mx-auto flex flex-col py-10  overflow-hidden `}>

        <div className='w-full flex gap-10 flex-col lg:flex-row px-5 md:px-10'>
          <div className='w-full lg:w-[40%] flex flex-col gap-5 mt-5'>

            <h2 className='text-[23px] font-poppins'>
              Helping people reach career heights and expand their ambitions through expertly-crafted resumes
            </h2>
            <h4 className='text-[14px] font-inter'>Save time with our easy 3-step resume builder. No more writer’s block or formatting difficulties in Word. Rapidly make a perfect resume employers love</h4>
            <button onClick={() => navigate("/resume-templates")} className="w-[150px] rounded-full text-white font-poppins text-[14px] h-[45px] bg-[#2A84FF]">
              Create Resume
            </button>
          </div>

          <div className='w-[700px] h-[380px]'>
            <img className='w-full h-full' src={about} alt="" />
          </div>
        </div>
        <div className='flex justify-center flex-col gap-8 items-center px-4  md:px-12  lg:px-20 bg-[#2A84FF] py-14 my-20'>
          <h2 className='text-[25px] text-white font-poppins font-semibold'>Our Mission</h2>
          <div className='font-poppins text-gray-100 flex flex-col gap-3 '>
            <p >The Quick Rezume seeks to enable job seekers to exhibit their exceptional capabilities, exploits and talents in the creation of aesthetically appealing, highly efficient resumes. We feel that every single person should be given the chance to present themselves as best they can possibly be, and we are committed to providing an uncomplicated resume creation method that is open to all. </p>
            <p>We aim at helping our users develop professional resumes that can attract employers by narrowing down the bridge between talent and opportunity. We strive for constant improvement so that our platform is up-to-date with both market demands and current resume design trends. </p>
            <p>Whether you are starting out, looking for a change or want to move upwards Quick Rezume will be there for you on your journey towards success. Your dream job needs only a good CV; we are here.</p>
          </div>
        </div>

        <div className='flex flex-col gap-10 items-center'>
          <h2 className='text-[25px] font-poppins font-semibold'>Our Values</h2>
          <div className='flex gap-10 flex-wrap justify-center px-3'>

            <div className='w-full md:w-[330px] flex flex-col gap-2 shadow-lg px-4 py-3'>
              <span className='flex gap-2 items-center'>
                <img className='w-[25px] h-[25px]' src={rocket} alt="" />
                <h4 className='font-poppins text-[20px]'>Accessibility</h4>
              </span>
              <p className='text-[13px] font-inter'>Our purpose is to provide a resume builder to all. Offer a free, easy-to-use tool for job seekers everywhere.</p>
            </div>

            <div className='w-full md:w-[330px] flex flex-col gap-2 shadow-lg px-4 py-3'>
              <span className='flex gap-2 items-center'>
                <img className='w-[25px] h-[25px]' src={rocket} alt="" />
                <h4 className='font-poppins text-[20px]'>Quality</h4>
              </span>
              <p className='text-[13px] font-inter'>We are dedicated to providing the highest quality templates with guidance to set your resume in a strong impression.
              </p>
            </div>

            <div className='w-full md:w-[330px] flex flex-col gap-2 shadow-lg px-4 py-3'>
              <span className='flex gap-2 items-center'>
                <img className='w-[25px] h-[25px]' src={rocket} alt="" />
                <h4 className='font-poppins text-[20px]'>Innovation</h4>
              </span>
              <p className='text-[13px] font-inter'>We innovate and update continuously to keep pace with trends and user feedback.</p>
            </div>

            <div className='w-full md:w-[330px] flex flex-col gap-2 shadow-lg px-4 py-3'>
              <span className='flex gap-2 items-center'>
                <img className='w-[25px] h-[25px]' src={rocket} alt="" />
                <h4 className='font-poppins text-[20px]'>Free to Use</h4>
              </span>
              <p className='text-[13px] font-inter'>Use all of the features of our resume builder absolutely free. We are all about providing quality tools for free to help as many people as possible.</p>
            </div>

            <div className='w-full md:w-[330px] flex flex-col gap-2 shadow-lg px-4 py-3'>
              <span className='flex gap-2 items-center'>
                <img className='w-[25px] h-[25px]' src={rocket} alt="" />
                <h4 className='font-poppins text-[20px]'>Support</h4>
              </span>
              <p className='text-[13px] font-inter'>We've got your back with resources and customer service to help you build the perfect resume</p>
            </div>

          </div>
        </div>

        <div className='px-10 flex flex-col my-20 gap-8'>
        <h2 className='text-[25px] font-poppins font-semibold'>What Our Users say?</h2>
          <div className='flex flex-col lg:flex-row gap-8 flex-wrap'>
              <div className='w-full md:w-[380px] shadow-lg px-2 py-3'>
                <div className='h-[70px] overflow-hidden -mt-10'>
                <img className='w-[100px] ' src={five_star} alt="" />
                </div>
                <h4 className='font-poppins'>John D., Software Engineer</h4>
                <p className='text-[12px] font-inter mt-1 italic'>"I’ve tried several resume builders, but Quick Rezume stands out for its simplicity and effectiveness. The customizable templates made my resume look polished and unique. Highly recommend!"</p>
              </div>

              <div className='w-full md:w-[350px] shadow-lg px-2 py-3'>
                <div className='h-[70px] overflow-hidden -mt-10'>
                <img className='w-[100px] ' src={five_star} alt="" />
                </div>
                <h4 className='font-poppins'>Emily R., Graphic Designer</h4>
                <p className='text-[12px] font-inter mt-1 italic'>"I love how easy this was to create such a beautiful resume with Quick Rezume. The interface is user-friendly, and their guidance helped me craft a resume that represents my skills and experience."</p>
              </div>

              <div className='w-full md:w-[350px] shadow-lg px-2 py-3'>
                <div className='h-[70px] overflow-hidden -mt-10'>
                <img className='w-[100px] ' src={five_star} alt="" />
                </div>
                <h4 className='font-poppins'>Michael T., Sales Manager</h4>
                <p className='text-[12px] font-inter mt-1 italic'>"I'm a person not very savvy with regard to technology, and I found Quick Rezume really easy to use. It was just great with the step-by-step and the professional templates. Thanks for the fantastic tool!"</p>
              </div>

          </div>
        </div>


      </div>
      <Footer />
    </>
  )
}

export default About
