import ResumeBuilder from "../modules/Builder"
import Profile from "../modules/Builder/Main-Builder/Form/pages/Profile/index"
import Education from "../modules/Builder/Main-Builder/Form/pages/Education/index"
import About from "../modules/About/About"
import Experience from "../modules/Builder/Main-Builder/Form/pages/Experience/index"
import Skills from "../modules/Builder/Main-Builder/Form/pages/Skills/index"
import Hobbies from "../modules/Builder/Main-Builder/Form/pages/Hobbies"
import Editor from "../modules/Builder/Editor"
import Download from '../modules/Builder/Main-Builder/Form/pages/download'

export const ROUTES = [
  {
    path:"/resume-builder/:id",
    component:ResumeBuilder,
    children:[
     {
      path: "profile",
      component: Profile
     },
     {
      path: "education",
      component: Education
     },
     {
      path: "experience",
      component: Experience
     }
     ,
     {
      path:"skills",
      component: Skills
     },
     {
      path:"hobbies",
      component:Hobbies
     },
     {
      path:"download",
      component:Download
     }
    ]
    
  },
  {
    path:"/resumes/edit",
    component:Editor
  }
 
]