import React from "react";
import AddSkills from "./addSkills";

const Skills = () => {
  return (
    <div className="w-full h-auto flex flex-col gap-4">
      <div className="w-full h-auto flex flex-col gap-2">
        <h2 className="text-darkblue sm:text-[18px] xl:text-[22px] font-poppins font-[500] ">
          Choose important Skills that you have
        </h2>
        {/* <p className="text-darkblue text-[14px] font-poppins ">
          Make sure they match the key skills mentioned in the job listing.
        </p> */}
      </div>
      <AddSkills/>
      <div className="w-full h-[20px]" ></div>
    </div>
  );
};

export default Skills;
