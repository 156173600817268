import React, { useState } from 'react'
import styles from '../../style'
import SendIcon from '@mui/icons-material/Send';
import contact from './contact.gif'
import Header from '../../Shared/Header';
import Footer from '../../Shared/Footer';
import { Helmet } from 'react-helmet';


const Contact = () => {

    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>

    const SERVERURL = 'http://localhost:4000';


    const [formState, setFormState] = useState({
        name: "",
        email: "",
        message: "",
        termsAccepted: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormState({
            ...formState,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${SERVERURL}/api/sendMail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: formState.name, email: formState.email, message: formState.message }),
            });

            console.log(formState.name, formState.email, formState.message)

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.text();
            alert(result);

        } catch (error) {
            console.log('Error:', error);
            alert('Failed to submit form. Please try again later.');
        }
        setFormState({
            ...formState,
            name: " ",
            email: " ",
            message: " "
        }); // Clear form fields after submission
    };

    return (
        <div className='mx-auto  '>
            <Header />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact</title>
                <link rel="canonical" href="/" />
            </Helmet>
            <div className='max-w-[1280px] mx-auto  flex flex-col lg:flex-row px-10 gap-20 items-center justify-center py-20'>
                <div className='flex flex-col gap-2 items-start'>
                    <h2 className='font-inter text-[30px] font-semibold'>Contact Us</h2>
                    <p className='font-poppins'> Email, call, or complete the form to contact us.</p>
                    <p className='font-poppins w-[400px] text-[14px]'>We value your feedback and are continuously working to improve Resume Builder. Your input is crucial in shaping the future of Resume Builder</p>
                    {/* <img className='w-[400px]' src={contact} alt="" /> */}
                    <lottie-player src="https://lottie.host/2a483a4d-9b6d-4516-9a63-51520cafabcd/db0NCLqCwY.json" background="##FFFFFF" speed="1" loop autoplay direction="1" mode="normal"></lottie-player>
                </div>

                <form onSubmit={handleSubmit} className='w-[430px] h-[580px] bg-white shadow-lg px-8 py-6 flex flex-col gap-5 rounded-xl '>
                    <h2 className='font-inter font-semibold text-[23px]'>Get in Touch</h2>
                    <p className='text-[14px] font-poppins'>You can reach us anytime</p>
                    <input onChange={(e)=>handleChange(e)} className='w-full h-[50px] box_shadow rounded-full px-5 font-poppins capitalize outline-none' placeholder='Name' type="text" name='name' value={formState.name} />
                    <input onChange={(e)=>handleChange(e)} className='w-full h-[50px] box_shadow rounded-full px-5 font-poppins outline-none' placeholder='Email' type="email" name='email' value={formState.email} id="" />
                    <textarea onChange={(e)=>handleChange(e)} className='w-full h-[250px] border-[1px] outline-none font-poppins px-5 py-2' name={"message"} id="" placeholder='Type your message' value={formState.message}/>
                    <button className='w-full bg-[#2A84FF] text-white h-[60px] rounded-full font-poppins uppercase text-[18px]'> Sumbit
                    </button>
                </form>

            </div>
            <Footer />
        </div>
    )
}

export default Contact
