import React,{useState} from "react";
import { useDispatch } from "react-redux";
import { setCurrentTemplate } from "../../../Redux/template";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const TemplateEditor = ({item}) => {

  const dispatch = useDispatch()

    const handleSelectTemplate = async () => {

     await dispatch(setCurrentTemplate(item))

     window.location.reload()
 toast.success(`Templated Selected`, {
            position: "top-right",
           },5000)
     
    }


  return (
    <div onClick={handleSelectTemplate}  className=" w-[1/2] h-[250px] cursor-pointer active:scale-95 transition-all  flex flex-col gap-1 text-center">
      <ToastContainer />
      <h2 className="text-white font-alata">{item.name}</h2>
      <img
        src={item.image}
        className="w-[160px] h-[220px] rounded-sm "
      />
    </div>
  );
};

export default TemplateEditor;
