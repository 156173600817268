import React, { useEffect, useState } from "react";
import "./education.css";
import Accordion from "./accordion";
import { useDispatch, useSelector } from "react-redux";
import { setTabCompletion , selectedTab } from "../../../../../../Redux/builder";
import {
  addNewEducation,
  updateEducation,
} from "../../../../../../Redux/education";
import { useNavigate } from "react-router-dom";
const DataEducation = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate()

  const educationEntries = useSelector(
    (state) => state.education.educationList
  );

  const currentTemplate = useSelector((state)=> state.template.currentTemplate)

 
  const AddNew = () => {
    const payload = {
      school: "",
      degree: "",
      startDate: {
        fieldValue: "",
        displayValue: "",
      },
      endDate: {
        fieldValue: "",
        displayValue: "",
      },
      currentStudy: false,
      city: "",
      summary: "",
    };

    dispatch(addNewEducation(payload));

  };

  const handleNext = () => {
    dispatch(setTabCompletion({ tabName: "Education", completed: true }));
    Navigate(`/resume-builder/${currentTemplate.name}/experience`)
    dispatch(selectedTab("Experience"))
  }

  const handleBack = () =>{
    dispatch(selectedTab('Profile'));
    Navigate(`/resume-builder/${currentTemplate.name}/profile`)
  }
 
  return (
    <div className="w-full h-auto flex flex-col gap-4">
      {/***Form***/}
      
      {educationEntries &&
        educationEntries.length > 0 &&
        educationEntries.map((item, index) => {
          return (
            <Accordion item={item} key={index} index={index}  />
          );
        })}
      <div
        onClick={AddNew}
        className="w-[97%] flex flex-row gap-2 items-center hover:bg-[#1a90f01c] cursor-pointer py-2 px-2 transition-all rounded-sm "
      >
        <span className="material-symbols-outlined text-[#1A91F0] text-[20px] font-[600] ">
          add
        </span>
        <span className="text-[#1A91F0] text-[14px] font-poppins font-[600] tracking-[0.3px] ">
          {educationEntries && educationEntries.length > 0
            ? "Add one more education"
            : "Add Education"}
        </span>
      </div>
      <div className="w-full h-auto flex sm:flex-col lg:flex-row sm:gap-4 lg:gap-4 mt-[30px]  ">
        <button onClick={handleBack} className="sm:w-full lg:w-1/2  h-[40px] text-black font-alata  flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm   hover:bg-[#0097e6] hover:text-white   ">
          {" "}
          Back{" "}
        </button>
        <button
          onClick={handleNext}
          className={`sm:w-full lg:w-1/2  h-[40px] text-white font-alata cursor-pointer   flex items-center justify-center border-[1px] border-[#D0D2D4] rounded-sm bg-[#0097e6]  hover:bg-[#0a072bfd]    `}
        >
          {" "}
          Next{" "}
        </button>
      </div>
    </div>
  );
};

export default DataEducation;
