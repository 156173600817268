import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentTemplate: JSON.parse(localStorage.getItem("CurrentTempalte")) || ""
};

export const templateSlice = createSlice({
  name: "Slice",
  initialState,
  reducers: {
   
    setCurrentTemplate:(state,action) => {
        state.currentTemplate = action.payload
        localStorage.setItem("CurrentTempalte",JSON.stringify(action.payload))
    }
  
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentTemplate } = templateSlice.actions;

export default templateSlice.reducer;
