import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  personalData : JSON.parse(localStorage.getItem("PersonalData")) || {
    profilePic:"",
    title : "",
    firstName:"", 
    lastName:"",
    email:"",
    phone:"",
    country:"",
    city:"",
    summary:""
  },
  profileImage:""
  
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
   setProfileData:(state,action) => {
    state.personalData = action.payload
   },
   setProfileImg:(state,action) => {
    state.profileImage = action.payload
   },
   
  },
});

// Action creators are generated for each case reducer function
export const {setProfileData,setProfileImg} = profileSlice.actions;

export default profileSlice.reducer;
