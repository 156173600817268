import React from 'react'
import Header from '../../Shared/Header'
import Footer from '../../Shared/Footer'
import { useNavigate } from 'react-router-dom'
import thumbnail1 from "./articles/Blog1/thumbnail.jpg"
import thumbnail2 from "./articles/Blog2/thumbnail2.jpg"
import thumbnail3 from "./articles/Blog3/thumbnail3.jpg"

import { Helmet } from 'react-helmet'


const Blogs = () => {

  const navigate = useNavigate()

  

  return (
    <div className='w-full'>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Blog . quickrezume.com</title>
            <link rel="canonical" href="/blog-page" />
         </Helmet>

      <div className='flex flex-col justify-center mx-auto max-w-[1280px] bg-white rounded-lg '>
      <Header  />
      </div>
      <div className={`w-full max-w-[1280px] mx-auto py-8 overflow-hidden px-5`}>
      <h2 className='flex justify-center mt-10 font-poppins text-[#2A84FF] text-[20px]  '>Blogs & Articles</h2>
      <h2 className='flex justify-center font-poppins text-[28px] font-semibold pb-10 '>Latest from our blog</h2>
        <div className='w-full flex gap-2 flex-col justify-center items-center md:flex-row flex-wrap py-10'>

          {/* Blog 1 */}

          <div onClick={()=>navigate('/blog/how-to-write-an-effective-resume')} className="w-[400px] cursor-pointer h-[350px] flex flex-col">
              <div className='w-full h-[220px] rounded-lg overflow-hidden'>
                <img src={thumbnail1} alt="" />
              </div>
              <p className='text-[18px] font-poppins mt-3'>How to write an Effective Resume</p>
              <p className='font-poppins text-[14px] text-blue-600'>Read more</p>
          </div>

          {/* Blog 2 */}

          <div onClick={()=>navigate('/blog/common-resume-mistakes-to-avoid')} className="w-[400px] cursor-pointer h-[350px] flex flex-col">
              <div className='w-full h-[220px] rounded-lg overflow-hidden '>
                <img className='h-full w-[600px]' src={thumbnail2} alt="" />
              </div>
              <p className='text-[18px] font-poppins mt-3'>Common Resume Mistakes to Avoid
              </p>
              <p className='font-poppins text-[14px] text-blue-600'>Read more</p>
          </div>

          {/* Blog 3 */}

          <div onClick={()=>navigate('/blog/what-is-an-ATS-Friendly-resume')} className="w-[400px] cursor-pointer h-[350px] flex flex-col">
              <div className='w-full h-[220px] rounded-lg overflow-hidden '>
                <img className='h-full w-[500px]' src={thumbnail3} alt="" />
              </div>
              <p className='text-[18px] font-poppins mt-3'>What is an ATS Friendly Resume & How To Create One?
              </p>
              <p className='font-poppins text-[14px] text-blue-600'>Read more</p>
          </div>


        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Blogs
