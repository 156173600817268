import {React, useRef} from 'react'
import Header from '../../Shared/Header'
import HeroSection from './Home'
import Steps from './Steps/Steps'
// import Review from './Review/Review'
import Tutorial from './Tutorial'
import Footer from '../../Shared/Footer'
import SelectTemplate from './SelectTemplate/SelectTemplate'
import Brand from './Brand'
import Reviews from './Testimonial'
import { Helmet } from 'react-helmet'
import FaqSection from './FAQ/FaqSection'
import MainText from './MainText'

const Home = () => {


  return (
    <div className=" w-full overflow-hidden z-10" >
      <Helmet>
            <meta charSet="utf-8" />
            <title>Quick Rezume - Home</title>
            <link rel="canonical" href="/" />
         </Helmet>
      <Header/>
      <HeroSection/>
      <MainText/>
      <Steps/>
      <SelectTemplate/>
      <Reviews/>
      <FaqSection/>
      <Footer/>
    </div>
  )
}

export default Home